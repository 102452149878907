import { SalesOrganizationProfileCode } from '../types'

export enum UserType {
  User = 'User',
  Operator = 'Operator'
}

export type UserDetailResponse = {
  id: string
  userName: string
  firstName: string
  lastName: string
  companyName?: string // NA Operator
  email: string
  salesOrganizationName?: string // NA Operator
  salesOrganizationProfileCode?: SalesOrganizationProfileCode // NA operator
  subscriberId?: string // NA Operator
  officeName?: string // NA Operator
  officeId?: string
  language: string
  userType: UserType
  userStatus: string
  roles: string[]
  upperLevelRoles: string[]
  emailConfirmed: boolean
  creationDate: string
  expirationDate: string
  changePasswordFirstLogin: boolean
  passwordExpired: boolean
  passwordExpirationDate: string
  passwordNeverExpired: boolean
  lastActivityDate: string
  lastLockoutDate: string
  lastLoginDate: string
  lastPasswordChangedDate: string
  isApproved: boolean
  isOnline: boolean
  userNotes: string
  domainCode: string
  phone?: string
  subscriber?: string
  isLocked: boolean
  enabledProducts: string[]
  upperRolesOffice: string[]
  upperRolesSubscriber: string[]
  scopes: string[]
  margoData: {
    isMargo: boolean
    user?: {
      userType: string
      loginAs: boolean
      isActive: boolean
      currency?: string
      aliases?: MargoAliasResponse[]
    }
  }
}

export type UserState = {
  userDetail?: UserDetailResponse
}

export type MargoAliasResponse = {
  alias?: string
  sso?: string
  userType?: string
  loginAs?: boolean
  isActive?: boolean
}
