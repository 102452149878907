export default {
  Office: 'Office',
  CreateOffice: 'Create office',
  EditOffice: 'Edit office',
  CreateOfficeDialog: {
    Success: {
      Title: 'Success',
      Text: 'Office {{officeName}} has been successfully created.'
    },
    Error: {
      Title: 'Error',
      Text: 'There was error while creating office {{officeName}}. Please try again later.'
    }
  },
  EditOfficeDialog: {
    Success: {
      Title: 'Success',
      Text: 'Office {{officeName}} has been successfully modified.'
    },
    Error: {
      Title: 'Error',
      Text: 'There was error while editing office {{officeName}}. Please try again later.'
    }
  },
  OfficeNotFound: 'Office not found'
}
