import _ from 'lodash'
import moment from 'moment'
import { getType, Reducer } from 'typesafe-actions'
import { Dictionary } from '../../utils/types'
import { ConfigActions } from '../config'

import { FiltersActions } from './actions'
import { FiltersState, FilterScope } from './types'

const defaultState: Partial<Record<FilterScope, { filters: Dictionary<unknown> }>> = {
  [FilterScope.UserList]: {
    filters: {
      userType: 'User'
    }
  }
}

export const initialState: FiltersState = _.reduce(
  _.values(FilterScope),
  (acc, value) => {
    acc[value] = defaultState[value] || { filters: {} }
    return acc
  },
  {} as FiltersState
)

export const filtersReducer: Reducer<FiltersState, FiltersActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(ConfigActions.setBriqConfig): {
      return {
        ...state,
        [FilterScope.BusinessOrdersDataList]: addDateFrom(
          action.payload.defaultSearchTimespanInDays,
          FilterScope.BusinessOrdersDataList
        ),
        [FilterScope.BusinessCompanyDetailDataList]: addDateFrom(
          action.payload.defaultSearchTimespanInDays,
          FilterScope.BusinessCompanyDetailDataList
        ),
        [FilterScope.BusinessCustomerRequestsDataList]: addDateFrom(
          action.payload.defaultSearchTimespanInDays,
          FilterScope.BusinessCustomerRequestsDataList
        ),
        [FilterScope.BusinessSearchesDataList]: addDateFrom(
          action.payload.defaultSearchTimespanInDays,
          FilterScope.BusinessSearchesDataList
        )
      }
    }
    case getType(FiltersActions.setFilters): {
      const { scope, filters } = action.payload
      return {
        ...state,
        [scope]: {
          ...state[scope],
          filters
        }
      }
    }
    default:
      return state
  }

  function addDateFrom(
    defaultSearchTimespanInDays: number | undefined,
    scope: FilterScope
  ): { filters: Dictionary<unknown> } {
    return {
      ...state[scope],
      filters: {
        ...state[scope].filters,
        dateFrom: state[scope].filters?.dateFrom ?? moment().subtract(defaultSearchTimespanInDays ?? 0, 'days')
      }
    }
  }
}
