import React from 'react'
import styled, { DefaultTheme } from 'styled-components/macro'
import { Spin as SpinAntd } from 'antd'
import { SpinProps } from 'antd/lib/spin'

type SpinType = 'primary' | 'secondary'

const colorByType = (t: DefaultTheme, type?: SpinType) => {
  if (type === 'secondary') {
    return t.colors.white
  }
  return t.colors.primary
}

export enum SpinSize {
  Small,
  ExtraLarge
}

const SmallSpin = styled(SpinAntd)<{ color?: string; type: SpinType }>`
  &.ant-spin {
    .ant-spin-dot {
      i {
        background-color: ${({ theme, color, type }) => color || colorByType(theme, type)};
      }
    }
  }
`

const ExtraLargeSpin = styled(
  ({
    color, // eslint-disable-line @typescript-eslint/no-unused-vars
    type, // eslint-disable-line @typescript-eslint/no-unused-vars
    dotSize, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...rest
  }: { color?: string; type: SpinType; dotSize: number } & Omit<SpinProps, 'size'>) => <SpinAntd {...rest} />
)`
  &.ant-spin {
    .ant-spin-dot {
      font-size: ${({ dotSize }) => dotSize * 2}px;
      i {
        width: ${({ dotSize }) => dotSize}px;
        height: ${({ dotSize }) => dotSize}px;
        background-color: ${({ theme, color, type }) => color || colorByType(theme, type)};
      }
    }
  }
`

type Props = {
  color?: string
  size?: SpinSize
  type?: SpinType
  dotSize?: number
} & Omit<SpinProps, 'size'>

export const Spin: React.FC<Props> = ({ size, color, type = 'primary', delay = 150, dotSize = 35, ...rest }) => {
  const def = <ExtraLargeSpin color={color} type={type} delay={delay} dotSize={dotSize} {...rest} />
  if (size === SpinSize.Small) {
    return <SmallSpin color={color} type={type} delay={delay} {...rest} />
  } else {
    return def
  }
}

Spin.displayName = 'Spin'
