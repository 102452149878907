import { getType, Reducer } from 'typesafe-actions'
import { withLoadingReducer } from '../loading'
import { TableKey } from '../table'
import { FilterScope } from '../filters'

import { AccessControlActions } from './actions'
import { AccessControlState, SelectedDataType } from './types'

export const initialState: AccessControlState = {}

export const reducer: Reducer<AccessControlState, AccessControlActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(AccessControlActions.selectData): {
      const filterScopeMap = {
        [SelectedDataType.SecurityBlockedUsers]: FilterScope.AccessControlBlockedUsersDataList,
        [SelectedDataType.SecurityBlockedSites]: FilterScope.AccessControlBlockedSitesDataList,
        [SelectedDataType.SecurityWhitelist]: FilterScope.AccessControlWhitelistDataList
      }
      const tableKeyMap = {
        [SelectedDataType.SecurityBlockedUsers]: TableKey.AccessControlBlockedUsersDataList,
        [SelectedDataType.SecurityBlockedSites]: TableKey.AccessControlBlockedSitesDataList,
        [SelectedDataType.SecurityWhitelist]: TableKey.AccessControlWhitelistDataList
      }
      return {
        ...state,
        product: action.payload.product,
        selectedDataType: action.payload.selectedDataType,
        tableKey: tableKeyMap[action.payload.selectedDataType],
        filterScope: filterScopeMap[action.payload.selectedDataType]
      }
    }
    case getType(AccessControlActions.setData):
      return {
        ...state,
        data: action.payload.data
      }
    case getType(AccessControlActions.resetData):
      return initialState
    default:
      return state
  }
}

export const accessControlReducer = withLoadingReducer(reducer, 'accessControl', initialState)
