import { Breadcrumb, BreadcrumbProps } from 'antd'
import _ from 'lodash'

export interface BreadcrumbItem {
  text?: string
  link?: string
}

export interface BreadcrumbsProps extends BreadcrumbProps {
  items: BreadcrumbItem[]
}

export const Breadcrumbs = ({ items, ...rest }: BreadcrumbsProps) => {
  if (_.isEmpty(items)) return null
  return (
    <Breadcrumb {...rest}>
      {_.map(items, x => (
        <Breadcrumb.Item key={x.link || x.text} href={x.link}>
          {x.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}
