import { ActionType, createAction } from 'typesafe-actions'
import { ColumnType } from '../../components/Table'

export const UserListActions = {
  loadUsers: createAction('USER_LIST/load-users')(),
  setUsers: createAction('USER_LIST/set-users')<{ users: any[] }>(),
  makeReport: createAction('USER_LIST/make-report')<{ columns: ColumnType[] }>()
}

export type UserListActions = ActionType<typeof UserListActions>
