import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { apiCall } from '../../utils/api'
import { withLoading } from '../loading'
import { UserActions } from './actions'
import { UserDetailResponse } from './types'
import { DialogActions } from '../dialog'
import { push } from 'connected-react-router'
import { Routes } from '../../utils/routes'

export function* fetchUserDetail(action: ReturnType<typeof UserActions.fetchUserDetail>) {
  // if not authorized ...
  const { userId } = action.payload
  const { data }: AxiosResponse<UserDetailResponse> = yield call(apiCall, {
    url: `/api/administration/user/${userId}`,
    method: 'get'
  })
  yield put(UserActions.setUserDetail({ userDetail: data }))
}

export function* postProcessUserEdit(action: ReturnType<typeof UserActions.postProcessUserEdit>) {
  const { payload } = action
  const { closeAfterSubmit, userId, postBulkConsumptionDetailsConfig, refreshConsumptionLimits } = payload

  let isError = false

  if (postBulkConsumptionDetailsConfig) {
    try {
      yield call(apiCall, postBulkConsumptionDetailsConfig)
    } catch {
      isError = true
      yield put(
        DialogActions.showInfoDialog({
          i18nText: 'user:UserPartialUpdatedText',
          i18nTitle: 'user:UserPartialUpdatedTitle',
          namespaces: ['user']
        })
      )
    } finally {
      refreshConsumptionLimits()
    }
  }

  if (!isError) {
    yield put(
      DialogActions.showInfoDialog({
        i18nText: 'user:UserUpdated',
        i18nTitle: 'core:Success',
        namespaces: ['core', 'user']
      })
    )
  }

  yield put(UserActions.fetchUserDetail({ userId }))

  if (closeAfterSubmit) {
    yield put(push(Routes.UserList))
  }
}

export function* userDetailSagas() {
  yield all([
    takeLatest(getType(UserActions.fetchUserDetail), withLoading('userDetail', fetchUserDetail)),
    takeLatest(getType(UserActions.postProcessUserEdit), withLoading('userDetail', postProcessUserEdit))
  ])
}
