import { getType } from 'typesafe-actions'
import { DialogActions } from './actions'
import { DialogState } from './types'

export const initState: DialogState = {
  visibleDialog: undefined,
  dialogData: {}
}

export const dialogReducer = (state: DialogState = initState, action: DialogActions): DialogState => {
  switch (action.type) {
    case getType(DialogActions.showDialog):
      return {
        ...state,
        visibleDialog: action.payload.dialog
      }
    case getType(DialogActions.showDialogWithData):
      return {
        ...state,
        visibleDialog: action.payload.dialog,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: action.payload.data
        }
      }
    case getType(DialogActions.setDialogData):
      return {
        ...state,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: action.payload.data
        }
      }
    case getType(DialogActions.updateDialogData):
      return {
        ...state,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: {
            ...state.dialogData[action.payload.dialog],
            ...action.payload.data
          }
        }
      }
    case getType(DialogActions.closeDialog):
      return {
        ...state,
        visibleDialog: undefined,
        dialogData: {}
      }
    default:
      return state
  }
}
