import axios, { AxiosError } from 'axios'
import { Store } from 'redux'
import { AuthActions } from '../redux/auth'
import { DialogActions } from '../redux/dialog'
import { getToken } from '../redux/auth/selectors'

function formatAuthHeader(token: string) {
  return `Bearer ${token}`
}

export function applyAxiosInterceptors(store: Store) {
  axios.interceptors.request.use(
    config => {
      const token = getToken(store.getState())
      if (token) {
        config.headers.Authorization = formatAuthHeader(token)
      }
      return config
    },
    error => Promise.reject(error)
  )

  axios.interceptors.response.use(undefined, async (error: AxiosError<any>) => {
    const axiosError = error
    if (axios.isCancel(error)) return Promise.reject(error)
    if (axiosError) {
      switch (axiosError.response?.status) {
        case 401: {
          store.dispatch(AuthActions.logout())
          break
        }
        case 500: {
          store.dispatch(
            DialogActions.showInfoDialog({
              title: 'Error',
              text:
                'Something went wrong during the operation execution. Please, try again or contact development team to report the issue'
            })
          )
          break
        }
      }
    }
    return Promise.reject(error)
  })
}
