import { getType, Reducer } from 'typesafe-actions'

import { UserListActions } from './actions'
import { UserListState } from './types'
import { withLoadingReducer } from '../loading'

export const initialState: UserListState = {
  data: []
}

export const reducer: Reducer<UserListState, UserListActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(UserListActions.setUsers):
      return {
        ...state,
        data: action.payload.users
      }
    default:
      return state
  }
}

export const userListReducer = withLoadingReducer(reducer, 'usersList', initialState)
