import core from './core'
import validation from './validation'
import login from './login'
import loginAs from './loginAs'
import menu from './menu'
import user from './user'
import form from './form'
import role from './role'
import subscriber from './subscriber'
import organization from './organization'
import office from './office'
import margo from './margo'
import password from './password'
import businessData from './businessData'
import accessControl from './accessControl'

export const en = {
  core,
  validation,
  login,
  loginAs,
  menu,
  user,
  form,
  role,
  subscriber,
  organization,
  office,
  margo,
  password,
  businessData,
  accessControl
}
