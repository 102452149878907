import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { PersistGate } from 'redux-persist/integration/react'
import { Persistor } from 'redux-persist/es/types'
import qs from 'qs'
import { App } from './app'
import { makeStore } from './redux/store'
import { i18n } from './i18n'
import { Store } from 'redux'
import axios from 'axios'
import { GlobalStyle, theme } from './theme/theme'
import { ThemeProvider } from 'styled-components/macro'
import { consoleOverride } from './utils/console'
import 'antd/dist/antd.min.css'
import { applyAxiosInterceptors } from './utils/axios'
import { ViewportSizeContextProvider } from './components/ViewPortSizeContextProvider'
import { ConfigActions } from './redux/config'
import { GlobalEventHandler } from './globalEventHandler'
import { getConfig } from './utils/configs'

const init = new Promise<{ store: Store; persistor: Persistor }>(resolve => {
  if (process.env.NODE_ENV === 'production') {
    disableReactDevTools()
  }

  const { store, persistor } = makeStore()

  consoleOverride(store)
  applyAxiosInterceptors(store)

  resolve({
    store,
    persistor
  })
})

export function stringifyGetParams(query: object) {
  return qs.stringify(query, { allowDots: true })
}

Promise.all([getConfig('config.json'), init]).then(([config, { store, persistor }]) => {
  if (!config.apiUrl) {
    // eslint-disable-next-line
    console.error('You need to set apiUrl in config.json')
    return
  }

  store.dispatch(
    ConfigActions.setConfig({
      autoLogoutTime: config.autoLogoutTime || 0,
      timeToCancelAutoLogout: config.timeToCancelAutoLogout || 60,
      isDiscoveryConfigured: undefined,
      domainConfigs: undefined
    })
  )

  axios.defaults.baseURL = config.apiUrl
  axios.defaults.paramsSerializer = stringifyGetParams

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalEventHandler />
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <ViewportSizeContextProvider>
              <GlobalStyle />
              <App />
            </ViewportSizeContextProvider>
          </I18nextProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
})
