import _ from 'lodash'
import { getLocation } from 'connected-react-router'
import { LogActions } from '../redux/logs'

export function consoleOverride(store: any) {
  const { console } = window
  if (!console) return

  function intercept(method: string) {
    const original = _.get(console, method)
    _.set(console, method, (...rest: string[]) => {
      const { pathname } = getLocation(store.getState())
      const stack = Array.prototype.slice.apply(rest).join(' ')
      store.dispatch(
        LogActions.addLogItem({
          message: `${method === 'warn' ? 'Warning' : 'Error'} message captured in console.`,
          stack,
          path: pathname,
          level: method === 'warn' ? 'WARNING' : 'ERROR'
        })
      )

      if (original.apply) {
        // normal browser
        original.apply(console, rest)
      } else {
        // IE
        original(stack)
      }
    })
  }
  const methods = ['warn', 'error']
  _.forEach(methods, (method: string) => intercept(method))
}
