import { getType } from 'typesafe-actions'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { UserListActions } from './actions'
import { apiCall } from '../../utils/api'
import { AxiosResponse } from 'axios'
import { withLoading } from '../loading'
import { PaginationConfig, TableActions, TableKey } from '../table'
import { selectPagination, selectSorting, selectVisibleColumns } from '../table/selectors'
import { FiltersActions, FilterScope, getFilterValues } from '../filters'
import { Dictionary } from '../../utils/types'
import { ColumnSorting } from '../../components/Table'
import { mapSortingToApi } from '../../utils/helpers'
import { exportData, mapExportHeaders } from '../../utils/export'

type UserResponse = {
  currentPage: 1
  pageSize: 25
  pagesCount: 1
  recordsCount: number
  records: {
    creationDate: string
    email: string
    firstName?: string
    isApproved: boolean
    isLockedOut: boolean
    isOnline: boolean
    phone?: string
    registrationType: string
    subscriberType: string
    surname?: string
    userName: string
    officeName?: string
    salesOrganizationName?: string
    subscriberName?: string
  }[]
}

export function* loadUsers() {
  const pagination: PaginationConfig = yield select(selectPagination, { tableKey: TableKey.UserList })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey: TableKey.UserList })
  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: FilterScope.UserList })

  const { data }: AxiosResponse<UserResponse> = yield call(apiCall, {
    url: `/api/users`,
    method: 'post',
    data: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters: { ...filters },
      sort: mapSortingToApi(sorting)
    }
  })

  yield put(UserListActions.setUsers({ users: data.records }))

  yield put(
    TableActions.setPagination({
      tableKey: TableKey.UserList,
      pagination: {
        current: pagination.current,
        pageSize: data.pageSize,
        total: data.recordsCount
      }
    })
  )
}

export function* exportUsers({ payload }: ReturnType<typeof UserListActions.makeReport>) {
  const { columns } = payload
  const visibleColumns: string[] = yield select(selectVisibleColumns, { tableKey: TableKey.UserList })
  const exportHeaders = mapExportHeaders(columns, visibleColumns)

  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: FilterScope.UserList })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey: TableKey.UserList })

  yield exportData('user', {
    filters: { ...filters },
    sort: mapSortingToApi(sorting),
    columns: exportHeaders
  })
}

export function* handleChangePagination(action: ReturnType<typeof TableActions.changePagination>) {
  if (action.payload.tableKey !== TableKey.UserList) return

  yield put(UserListActions.loadUsers())
}

export function* handleChangeSorting(action: ReturnType<typeof TableActions.setSorting>) {
  if (action.payload.tableKey !== TableKey.UserList) return
  yield put(TableActions.setPage({ tableKey: TableKey.UserList, page: 1 }))
  yield put(UserListActions.loadUsers())
}

export function* handleChangeFilters(action: ReturnType<typeof FiltersActions.setFilters>) {
  if (action.payload.scope !== FilterScope.UserList) return

  yield put(TableActions.setPage({ tableKey: TableKey.UserList, page: 1 }))
  yield put(UserListActions.loadUsers())
}

export function* userListSagas() {
  yield all([
    takeLatest(getType(UserListActions.loadUsers), withLoading('usersList', loadUsers)),
    takeLatest(getType(TableActions.changePagination), handleChangePagination),
    takeLatest(getType(FiltersActions.setFilters), handleChangeFilters),
    takeLatest(getType(TableActions.setSorting), handleChangeSorting),
    takeLatest(getType(UserListActions.makeReport), exportUsers)
  ])
}
