import { getType } from 'typesafe-actions'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { TransactionListActions } from './actions'
import { apiCall } from '../../utils/api'
import { AxiosResponse } from 'axios'
import { withLoading } from '../loading'
import { PaginationConfig, TableActions, TableKey } from '../table'
import { selectPagination, selectSorting } from '../table/selectors'
import { FiltersActions, FilterScope, getFilterValues } from '../filters'
import { Dictionary } from '../../utils/types'
import { ColumnSorting } from '../../components/Table'
import { mapSortingToMargoApi } from '../../utils/helpers'
import { Transaction } from './types'
import { getSubscriptionId } from './selectors'

type TransactionResponse = { transactions: Transaction[] }
type TransactionCountResponse = { count: number }

export function* loadTransactions() {
  const pagination: PaginationConfig = yield select(selectPagination, { tableKey: TableKey.TransactionList })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey: TableKey.TransactionList })
  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: FilterScope.TransactionList })
  const subscriptionId: string = yield select(getSubscriptionId)

  const { data: countData }: AxiosResponse<TransactionCountResponse> = yield call(apiCall, {
    url: `/api/transactions/count`,
    method: 'post',
    data: {
      subscriptionId,
      ...filters
    }
  })

  yield put(
    TableActions.setPagination({
      tableKey: TableKey.TransactionList,
      pagination: {
        ...pagination,
        total: countData.count
      }
    })
  )

  const { data: transactionData }: AxiosResponse<TransactionResponse> = yield call(apiCall, {
    url: `/api/transactions`,
    method: 'post',
    data: {
      subscriptionId,
      skip: (pagination.current - 1) * pagination.pageSize,
      top: pagination.pageSize,
      sort: mapSortingToMargoApi(sorting),
      ...filters
    }
  })

  yield put(TransactionListActions.setTransactions({ transactions: transactionData.transactions }))
}

export function* handleChangePagination(action: ReturnType<typeof TableActions.changePagination>) {
  if (action.payload.tableKey !== TableKey.TransactionList) return

  yield put(TransactionListActions.loadTransactions())
}

export function* handleChangeSorting(action: ReturnType<typeof TableActions.setSorting>) {
  if (action.payload.tableKey !== TableKey.TransactionList) return
  yield put(TableActions.setPage({ tableKey: TableKey.TransactionList, page: 1 }))
  yield put(TransactionListActions.loadTransactions())
}

export function* handleChangeFilters(action: ReturnType<typeof FiltersActions.setFilters>) {
  if (action.payload.scope !== FilterScope.TransactionList) return

  yield put(TableActions.setPage({ tableKey: TableKey.TransactionList, page: 1 }))
  yield put(TransactionListActions.loadTransactions())
}

export function* transactionListSagas() {
  yield all([
    takeLatest(getType(TransactionListActions.loadTransactions), withLoading('transactionList', loadTransactions)),
    takeLatest(getType(TableActions.changePagination), handleChangePagination),
    takeLatest(getType(FiltersActions.setFilters), handleChangeFilters),
    takeLatest(getType(TableActions.setSorting), handleChangeSorting)
  ])
}
