import _ from 'lodash'
import { css, DefaultTheme } from 'styled-components/macro'
import { Color } from '../../theme'

export const formatStyleSize = (size: number | string): string => {
  if (_.isNumber(size)) {
    return `${size}px`
  }
  return size
}

export type Offset =
  | {
      top?: string | number
      left?: string | number
      right?: string | number
      bottom?: string | number
    }
  | string
  | number

const offsets = (cssProp: string, offset: Offset) => {
  if (_.isNumber(offset) || _.isString(offset)) {
    return `${cssProp}: ${formatStyleSize(offset)};`
  }
  const { top, left, right, bottom } = offset
  return `${cssProp}: ${formatStyleSize(top || 0)} ${formatStyleSize(right || 0)} ${formatStyleSize(
    bottom || 0
  )} ${formatStyleSize(left || 0)};`
}

export const ellipsisMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export type TextMixinProps = {
  size: string
  lineHeight: number
  bold: boolean
  center: boolean
  ellipsis: boolean
  margin?: Offset
  padding?: Offset
  color?: Color
  onClick?: () => void
}

export const textMixin = ({
  size,
  lineHeight,
  bold,
  center,
  ellipsis,
  margin,
  padding,
  onClick
}: TextMixinProps) => css`
  fill: currentColor;
  font-size: ${size};
  line-height: ${lineHeight};
  font-weight: ${bold ? 'bold' : 'normal'};
  ${ellipsis &&
  css`
    && {
      ${ellipsisMixin}
    }
  `};
  ${center && 'text-align: center;'}
  ${!_.isNil(margin) ? offsets('margin', margin) : ''}
  ${!_.isNil(padding) ? offsets('padding', padding) : ''}
  ${onClick &&
  css`
    :hover {
      cursor: pointer;
    }
  `}
`

export type TitleSize = 'small' | 'medium' | 'large' | 'extraLarge'

type TitleLevelProps = {
  as: 'h1' | 'h2' | 'h3' | 'h4'
  size: string
  bold: boolean
}

export const titleLevelProps = (theme: DefaultTheme, level?: TitleSize) => {
  const TITLE_LEVELS: Record<TitleSize, TitleLevelProps> = {
    extraLarge: { as: 'h1', size: theme.titleFontSizes.extraLarge, bold: true },
    large: { as: 'h2', size: theme.titleFontSizes.large, bold: true },
    medium: { as: 'h3', size: theme.titleFontSizes.medium, bold: true },
    small: { as: 'h4', size: theme.titleFontSizes.small, bold: false }
  }

  return TITLE_LEVELS[level || 'large']
}
