import Axios from 'axios'
import { all, put, call } from 'redux-saga/effects'
import { LogActions } from './logs'
import { mapActionError } from '../utils/helpers'
import { authSagas } from './auth'
import { userListSagas } from './userList'
import { userDetailSagas } from './user'
import { roleListSagas } from './roleList'
import { subscriberSagas } from './subscriber'
import { transactionListSagas } from './transactionList'
import { businessDataSagas } from './businessData'
import { notificationSagas } from './notifications'
import { accessControlSagas } from './accessControl'
import { sapAsyncSagas } from './sapAsync'
import { configSagas } from './config'

function* allSagas() {
  yield all([
    authSagas(),
    userListSagas(),
    userDetailSagas(),
    roleListSagas(),
    subscriberSagas(),
    transactionListSagas(),
    businessDataSagas(),
    notificationSagas(),
    accessControlSagas(),
    sapAsyncSagas(),
    configSagas()
  ])
}

export function* rootSaga() {
  while (true) {
    try {
      yield call(allSagas)
    } catch (e: any) {
      if (!Axios.isCancel(e)) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error('Root saga crashed, restarting...', e)
        }

        yield put(LogActions.addLogItem(mapActionError(e, 'ERROR', window.location.pathname)))
      }
    }
  }
}
