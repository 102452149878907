import React from 'react'
import { Layout, Menu, Dropdown } from 'antd'
import styled from 'styled-components/macro'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, Breadcrumbs } from '../Breadcrumbs'
import _ from 'lodash'
import { Title, Text } from '../Text'
import { useDispatch, useSelector } from 'react-redux'
import { getFullName, isUserLoggedIn } from '../../redux/auth/selectors'
import { Button } from '../Button'
import { AuthActions } from '../../redux/auth'

const { Header, Content } = Layout

const StyledHeader = styled(Header)`
  padding: 0 16px;
  height: auto;
  background: #fff;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContentWrapper = styled.div`
  margin-top: 16px;
  padding: 24px;
  min-height: 360px;
  background: #fff;
`

const AccountHeaderInfo = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }

  .open-icon {
    transform: rotate(0);
    transition: all 0.25s ease-in;
  }

  .ant-dropdown-open {
    .open-icon {
      transform: rotate(-180deg);
    }
  }
`

type Props = {
  title?: string
  breadcrumbs?: BreadcrumbItem[]
}

export const PageWrapper: React.FC<Props> = ({ children, title, breadcrumbs }) => {
  const { t } = useTranslation('core')
  const fullName = useSelector(getFullName)
  const isLoggedIn = useSelector(isUserLoggedIn)

  const dispatch = useDispatch()

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => dispatch(AuthActions.logout())}>
        {t('core:Logout')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <StyledHeader>
        <Title>{title}</Title>
        {isLoggedIn && (
          <AccountHeaderInfo>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button variant="primary">
                <UserOutlined width="20px" />{' '}
                <Text px={2} color="white">
                  {fullName}
                </Text>
                <DownOutlined className="open-icon" width="20px" />
              </Button>
            </Dropdown>
          </AccountHeaderInfo>
        )}
      </StyledHeader>
      <Content style={{ margin: '0 16px' }}>
        {_.size(breadcrumbs) > 1 && <Breadcrumbs items={breadcrumbs || []} />}
        <ContentWrapper>{children}</ContentWrapper>
      </Content>
    </>
  )
}
