import { AxiosResponse } from 'axios'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { apiCall } from '../../utils/api'
import { withLoading } from '../loading'
import { SapAsyncActions } from './actions'
import { getIsDiscoveryConfigured } from '../config'

type SapAsyncResponse = {
  isDiscoveryConfigured: boolean | undefined
  isOnline: boolean | undefined
}

export function* fetchSapAvailability() {
  const isDiscoveryConfigured: boolean | undefined = yield select(getIsDiscoveryConfigured)

  if (isDiscoveryConfigured === false) {
    return
  }

  const { data }: AxiosResponse<SapAsyncResponse> = yield call(apiCall, {
    url: `/api/serviceDiscovery/SAP`,
    method: 'get'
  })

  yield put(SapAsyncActions.setSapAvailability({ ...data }))
}

export function* sapAsyncSagas() {
  yield all([takeLeading(getType(SapAsyncActions.loadSapAvailability), withLoading('sapAsync', fetchSapAvailability))])
}
