export default {
  Required: 'Field {{- label}} is required',
  InvalidEmailOrPassword: 'Invalid email or password',
  Regex: 'Field {{- label}} {{message}}',
  StrongPassword:
    'The password must be between 8 and 25 characters (letters or numbers). The password must contain at least one capital letter and one number. At most two consecutive identical characters',
  MustMatch: '{{sourceProperty}} must match {{targetProperty}}',
  MinLength: 'Field {{- label}} must contain more than {{min}} characters',
  MaxLength: 'Field {{- label}} can not contain more than {{max}} characters',
  Length: 'Field {{- label}} have to be between {{min}} and {{max}} characters',
  IsNotValidEmailAddress: 'is not valid email address',
  ExpirationDate: 'Expiration date can not be less than creation date.',
  UserNotAuthorizedForLogin: "User don't have required authorization for login.",
  CannotBeMoreThan: 'Field {{- label}} cannot be more than {{value}}',
  CannotBeMoreOrEqualThan: 'Field {{- label}} cannot be more or equal to {{value}}',
  CannotBeLessThan: 'Field {{- label}} cannot be less than {{value}}',
  CannotBeLessOrEqualThan: 'Field {{- label}} cannot be less or equal to {{value}}',
  NotNumber: 'Field {{- label}} is not a number',
  NewPasswordSameAsOld: "New password can't be same as old password.",
  True: 'Field {{- label}} is required to be true',
  DateFromGreaterThanDateTo: 'Date (From) can not be greater than Date (To).',
  DateToLessThanDateTo: 'Date (To) can not be less than Date (From).',
  IP: 'Field {{- label}} is not a valid IP',
  Phone: 'Invalid phone number',
  MaxUsers:
    'Active user are more than Max users purchased, please check the contract and change the value or de-activate some user',
  CannotBeLessThanOfficeUsers: 'Field {{- label}} cannot be less than what set for office users',
  ErrorWhenValidatingSso: 'Error when validating SSO',
  InvalidSsoValidationResponse: 'Invalid validation result',
  SsoInUse: 'SSO used by {{email}}'
}
