import { ActionType, createAction } from 'typesafe-actions'
import { Claim } from './types'

export const AuthActions = {
  setToken: createAction('AUTH/set-token')<{
    token: string
  }>(),
  refreshToken: createAction('AUTH/refresh-token')(),
  logout: createAction('AUTH/logout')(),
  setClaims: createAction('AUTH/set-claims')<{
    claims: Claim[]
  }>(),
  fetchClaims: createAction('AUTH/fetch-claims')()
}

export type AuthActions = ActionType<typeof AuthActions>
