export default {
  briq: 'BRIQ',
  adminx: 'ADMINx',
  margo: 'Margo',
  ConfirmDelete: 'Confirm delete',
  ConfirmUserUnblock: 'Unblock user',
  SecurityBlockedUsers: 'Security blocked users',
  SecurityBlockedSites: 'Security blocked sites',
  SecurityWhiteList: 'Security white list',
  SalesOrganization: 'Sales organization',
  UserName: 'Username',
  Email: 'Email',
  LoggedUsername: 'Logged username',
  IpAddress: 'IP address',
  Date: 'Date',
  UnblockedAt: 'Unblocked at',
  UserAgent: 'User agent',
  DateFrom: 'Date from',
  DateTo: 'Date to',
  UnblockUserDialogMsg: 'Are you sure you want to unblock this user?',
  DeleteMsgError: 'Unexpected error occurred. Please try close this dialog and try again.',
  UnblockBlockedUserDialogMsgSuccess: 'The user {{username}} was successfully unblocked. You can close this modal.',
  UnblockSiteDialogMsg: 'Are you sure you want to unblock this site?',
  Unblock: 'Unblock',
  ConfirmSiteUnblock: 'Unblock site',
  UnblockSiteDialogMsgSuccess: 'Site was successfully unblocked.',
  DeleteWhitelistDialogMsg: 'Are you sure you want to delete user from the whitelist?',
  DeleteWhitelistMsgSuccess: 'The user {{username}} was successfully deleted. You can close this modal.',
  ManageBlacklist: 'Manage blacklist',
  AddToBlacklist: 'Add to blacklist',
  ManageWhitelist: 'Manage whitelist',
  AddToWhitelist: 'Add to whitelist',
  UnblockSite: 'Unblock site',
  UserAgentTag: 'User agent tag',
  IP: 'IP',
  ManageBlacklistSuccess: 'The item was successfully add to blacklist.',
  ManageBlacklistError:
    'Unexpected error ocurred during adding item to blacklist. Please close this modal and try again later.',
  ManageWhitelistSuccess: 'The item was successfully add to whitelist.',
  ManageWhitelistError:
    'Unexpected error ocurred during adding item to whitelist. Please close this modal and try again later.'
}
