import { ActionType, createAction } from 'typesafe-actions'
import { SubscriberDetailResponse, SubscriptionLicenseUpdateRequest } from './types'

export const SubscriberActions = {
  setSubscriberDetail: createAction('SUBSCRIBER/set-subscriber-detail')<
    | {
        data: SubscriberDetailResponse
      }
    | undefined
  >(),
  setSalesOrganization: createAction('SUBSCRIBER/set-sales-organization')<
    | {
        salesOrganizationId: string
      }
    | undefined
  >(),
  margoManagementInitPopup: createAction('SUBSCRIBER/margoManagementInitPopup')<{
    subscriberId: string
    organizationId: string
  }>(),
  margoSubscriptionPostProcess: createAction('SUBSCRIBER/margoSubscriptionPostProcess')<{
    mode: 'create' | 'edit'
    subscriptionLicenseRequest: SubscriptionLicenseUpdateRequest
    subscriberDetail: SubscriberDetailResponse
    maxUsersPurchased: number | undefined
    previousLicenseCode: string | undefined
  }>()
}

export type SubscriberActions = ActionType<typeof SubscriberActions>
