import { createSelector } from 'reselect'
import { RootState } from '../types'
import { paramSelector } from '../../utils/selectors'
import { FilterScope } from './types'

type WithFilterScope = {
  scope: FilterScope
}

const selectState = (state: RootState) => state.filters

export const getFilterValues = createSelector(
  selectState,
  paramSelector<WithFilterScope>(),
  (s, { scope }) => s[scope].filters
)
