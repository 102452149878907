/* eslint-disable camelcase */
export default {
  Logout: 'Logout',
  Edit: 'Edit',
  Import: 'Import',
  Close: 'Close',
  BackToSearch: 'Go back to search',
  LoginAs: 'Login AS',
  Filters: 'Filters',
  Success: 'Success',
  Error: 'Error',
  Delete: 'Delete',
  Save: 'Save',
  Continue: 'Continue',
  Cancel: 'Cancel',
  SaveChanges: 'Save your changes',
  SaveAndClose: 'Save and close',
  Yes: 'Yes',
  No: 'No',
  Ok: 'Ok',
  Confirmation: 'Confirmation',
  Apply: 'Apply',
  Reset: 'Reset',
  Clear: 'Clear',
  AutoLogout: {
    Title: 'Inactive',
    Text: 'It looks like that you are inactive. You will be automatically logged off in {{count}} second.',
    Text_plural: 'It looks like that you are inactive. You will be automatically logged off in {{count}} seconds.'
  },
  EditConsumptionLimit: 'Edit consumption limit',
  SapOffline: 'It is temporarily not possible to interact with the functions because SAP is not available.'
}
