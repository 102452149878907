import { useSelector, useDispatch } from 'react-redux'
import { HubEventType } from './redux/notifications/types'
import { useReduxEvent } from './utils/eventr'
import { BusinessDataActions } from './redux/businessData'
import { DialogActions } from './redux/dialog'
import { getVisibleDialog } from './redux/dialog/selectors'

export const GlobalEventHandler = () => {
  const visibleDialog = useSelector(getVisibleDialog)
  const dispatch = useDispatch()

  useReduxEvent(() => {
    if (visibleDialog === 'DownloadData') {
      dispatch(
        DialogActions.updateDialogData({
          dialog: 'DownloadData',
          data: {
            isError: true
          }
        })
      )
    } else {
      dispatch(DialogActions.closeDialog())
      dispatch(
        DialogActions.showDialogWithData({
          dialog: 'DownloadData',
          data: {
            isError: true
          }
        })
      )
    }
  }, HubEventType.ExportFailed)

  useReduxEvent(event => {
    const { exportId } = event.data
    dispatch(DialogActions.closeDialog())
    dispatch(BusinessDataActions.downloadData({ exportId }))
  }, HubEventType.ExportCompleted)

  return <></>
}
