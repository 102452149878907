export type AuthState = {
  token: string
  claims: Claim[]
}

export type TokenPayload = {
  'admx.email': string
  'admx.firstname': string
  'admx.lastname': string
  'admx.userid': string
  'admx.username': string
  aud: string
  iss: string
  exp: number
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': UserRole[]
}

export enum UserRole {
  AdminXAdminMY = 'AdminXAdmin_MY',
  AdminXAdminID = 'AdminXAdmin_ID',
  MargoPH = 'Margo_PH',
  MargoAdminID = 'MargoAdmin_ID',
  MargoID = 'Margo_ID',
  MargoCH = 'Margo_CH',
  MargoAT = 'Margo_AT',
  MargoAdminPH = 'MargoAdmin_PH',
  MargoAdminCH = 'MargoAdmin_CH',
  MargoAdminAT = 'MargoAdmin_AT',
  AdminXRolesFun = 'AdminXRolesFun',
  BriqLoginAs = 'BriqLoginAs',
  BriqBusinessDataVisibility = 'BriqBusinessDataVisibility',
  MargoBusinessDataVisibility = 'MargoBusinessDataVisibility',
  AdminXBusinessDataVisibility = 'ADMINxBusinessDataVisibility',
  BriqAccessControlVisibility = 'BriqAccessControlVisibility',
  MargoAccessControlVisibility = 'MargoAccessControlVisibility',
  AdminXAccessControlVisibility = 'ADMINxAccessControlVisibility'
}

export enum ClaimCode {
  AdminXRolesFun = 'AdminXRolesFun',
  AdminXImportSubFunVisibility = 'AdminXImportSubFunVisibility',
  AdminXCreateSubFunVisibility = 'AdminXCreateSubFunVisibility',
  AdminXMargoFun = 'AdminXMargoFun'
}

export type Claim = {
  code: ClaimCode
  description: string
  type: string
}

export type ClaimsResponse = {
  claims: Claim[]
}

export enum SalesOrganization {
  CrifCH = 'CRIF_CH',
  CrifAT = 'CRIF_AT'
}

export type AuthorizedSelectOption = {
  value: string
  label: string
  allowedSalesOrganizations: SalesOrganization[]
}
