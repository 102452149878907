import { Layout, Menu } from 'antd'
import { UserOutlined, TeamOutlined, CheckCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Routes } from '../../utils/routes'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getUserRoles, getUserClaimCodes, isUserLoggedIn } from '../../redux/auth/selectors'
import { useTranslation } from 'react-i18next'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { TFunction } from 'i18next'
import { createTFunction } from '../../utils/helpers'
import { UserRole, ClaimCode } from '../../redux/auth'
import { isAuthorizedByAllClaims, isAuthorizedByAllRoles } from '../../utils/authorization'
import { useSapDiscovery } from '../../hooks/useSapDiscovery'
import { Box } from '../Box'

const { Sider } = Layout
const { SubMenu } = Menu

const StyledLayout = styled(Layout)`
  min-height: 100vh;

  .logo {
    color: white;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }

  .layout {
    margin-left: 200px;
    transition: all 0.2s;

    &.collapsed {
      margin-left: 80px;
    }
  }
`

type MenuConfigItem = {
  text: string
  url?: string
  children?: MenuConfigItem[]
  icon?: React.ReactNode
  requiresLogin?: boolean
  requiredClaims?: ClaimCode[]
  requiredRoles?: UserRole[]
  dependsOnSap?: boolean
}

const getMenuConfig = (t: TFunction): MenuConfigItem[] => {
  const tm = createTFunction('menu:', t)
  return [
    {
      text: tm('Users'),
      url: Routes.UserList,
      icon: <TeamOutlined />,
      requiresLogin: true
    },
    {
      text: tm('Roles'),
      url: Routes.RoleList,
      icon: <CheckCircleOutlined />,
      requiresLogin: true,
      requiredClaims: [ClaimCode.AdminXRolesFun]
    },
    {
      text: tm('ResetPassword'),
      url: Routes.ResetPasswordEmpty,
      icon: <CheckCircleOutlined />,
      requiresLogin: true
    },
    {
      text: tm('Login'),
      url: Routes.Login,
      icon: <UserOutlined />
    },
    {
      text: tm('ImportSubscriber'),
      url: Routes.ImportSubscriber,
      icon: <UserOutlined />,
      requiresLogin: true,
      requiredClaims: [ClaimCode.AdminXImportSubFunVisibility],
      dependsOnSap: true
    },
    {
      text: tm('CreateSubscriber'),
      url: Routes.CreateSubscriber,
      icon: <UserOutlined />,
      requiresLogin: true,
      requiredClaims: [ClaimCode.AdminXCreateSubFunVisibility]
    },
    {
      text: tm('SearchOrganizations'),
      url: Routes.SearchOrganizations,
      icon: <UserOutlined />,
      requiresLogin: true
    },
    {
      text: tm('BusinessData'),
      url: Routes.BusinessData,
      icon: <UserOutlined />,
      requiresLogin: true,
      requiredRoles: [UserRole.BriqBusinessDataVisibility]
    },
    {
      text: tm('AccessControl'),
      url: Routes.AccessControl,
      icon: <UserOutlined />,
      requiresLogin: true,
      requiredRoles: [UserRole.BriqAccessControlVisibility]
    }
  ]
}

export const SiderLayout: React.FC = ({ children }) => {
  const { t } = useTranslation(['menu', 'core'])
  const tc = createTFunction('core:', t)

  const [savedCollapsed, saveCollapsed] = useSessionStorage('sidebarCollapsed', '')

  const [collapsed, setCollapsed] = useState(!!savedCollapsed)
  const { pathname } = useLocation()
  const loggedIn = useSelector(isUserLoggedIn)
  const userClaims = useSelector(getUserClaimCodes)
  const userRoles = useSelector(getUserRoles)

  useEffect(() => {
    saveCollapsed(collapsed ? 'true' : '')
  }, [collapsed, saveCollapsed])

  const menuItems = _.compact(
    _.map(getMenuConfig(t), x => {
      if (x.requiresLogin && !loggedIn) return null
      if (!x.requiresLogin && loggedIn) return null
      if (
        !isAuthorizedByAllClaims(userClaims, x.requiredClaims) ||
        !isAuthorizedByAllRoles(userRoles, x.requiredRoles)
      ) {
        return null
      }

      const children = _.compact(
        _.map(x.children, ch => {
          if (ch.requiresLogin && !loggedIn) return null
          if (!ch.requiresLogin && loggedIn) return null
          if (
            !isAuthorizedByAllClaims(userClaims, ch.requiredClaims) ||
            !isAuthorizedByAllRoles(userRoles, ch.requiredRoles)
          ) {
            return null
          }

          return ch
        })
      )

      if (_.isEmpty(children) && !_.isEmpty(x.url)) {
        return { menuItem: x, menuItemChildren: children }
      } else if (!_.isEmpty(children)) {
        return { menuItem: x, menuItemChildren: children }
      }
      return null
    })
  )

  const menuDependsOnSap = _.some(menuItems, m => m.menuItem.dependsOnSap)
  const { isSapOffline } = useSapDiscovery({ skip: !menuDependsOnSap })

  return (
    <StyledLayout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
      >
        <Link to={Routes.Home}>
          <div className="logo">{collapsed ? 'A' : 'AdminX'}</div>
        </Link>
        <Menu
          theme="dark"
          selectedKeys={_.slice(
            _.reduce(
              _.values(Routes),
              (acc, value) => {
                if (value == '/' && pathname != '/') {
                  return acc
                }

                if (_.startsWith(pathname, value)) acc.push(value)
                return acc
              },
              [] as string[]
            ).sort((a, b) => _.size(b) - _.size(a)),
            0,
            1
          )}
          mode="inline"
        >
          {_.map(menuItems, ({ menuItem, menuItemChildren }) => {
            if (_.isEmpty(menuItemChildren) && !_.isEmpty(menuItem.url)) {
              return (
                <Menu.Item
                  key={menuItem.url || menuItem.text}
                  icon={menuItem.icon}
                  disabled={menuItem.dependsOnSap && isSapOffline}
                >
                  {menuItem.url ? (
                    <Link to={menuItem.url}>
                      {`${menuItem.text}${menuItem.dependsOnSap && isSapOffline ? ' *' : ''}`}
                    </Link>
                  ) : (
                    <div>{menuItem.text}</div>
                  )}
                </Menu.Item>
              )
            } else if (!_.isEmpty(menuItemChildren)) {
              return (
                <SubMenu key={menuItem.text} icon={menuItem.icon} title={menuItem.text}>
                  {_.map(menuItemChildren, ch => {
                    return (
                      <Menu.Item key={ch.url || ch.text} icon={ch.icon}>
                        {ch.url ? <Link to={ch.url}>{ch.text}</Link> : <div>{ch.text}</div>}
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              )
            }
            return null
          })}
        </Menu>
        {menuDependsOnSap && isSapOffline && (
          <Box p={1} color={'textPrimaryInverse'}>
            <b>{`* ${tc('SapOffline')}`}</b>
          </Box>
        )}
      </Sider>
      <Layout className={'layout ' + (collapsed ? 'collapsed' : '')}>{children}</Layout>
    </StyledLayout>
  )
}
