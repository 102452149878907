import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'
import { call, cancelled } from 'redux-saga/effects'

// istanbul ignore next
export const makeApiCall = (axios: AxiosInstance, config: AxiosRequestConfig) => axios(config)

export function createRequestParams(config: AxiosRequestConfig, { cancelToken }: { cancelToken?: CancelToken } = {}) {
  const reqData: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config.headers
    },
    cancelToken
  }

  return reqData
}

export function* apiCall<Response>({
  ...axiosConfig
}: AxiosRequestConfig & { overrideLang?: string }): Generator<any, AxiosResponse<Response>, any> {
  const source = axios.CancelToken.source()
  try {
    const reqData = createRequestParams(axiosConfig, {
      cancelToken: source.token
    })

    const res = yield call(makeApiCall, axios, reqData)
    return res
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled')
    }
  }
}
