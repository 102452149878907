import _ from 'lodash'
import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { AxiosResponse } from 'axios'
import { apiCall } from '../../utils/api'
import { BusinessDataActions } from './actions'
import { PaginationConfig, TableActions, TableKey, ColumnSorting } from '../table'
import { FiltersActions } from '../filters'
import { getFilterValues } from '../filters'
import { SelectedDataType, Searches, CompanyDetailViews, Orders, CustomerRequests } from './types'
import { getSelectedDataType } from './selectors'
import { withLoading } from '../loading'
import { selectPagination, selectSorting, selectVisibleColumns } from '../table/selectors'
import { Dictionary } from '../../utils/types'
import { getFilterScope, getTableKey } from '.'
import { mapSortingToApi } from '../../utils/helpers'
import { FilterScope } from '../filters'
import moment, { Moment } from 'moment'
import { exportData, mapExportHeaders, downloadExportedData } from '../../utils/export'

type BusinessDataResponse = {
  currentPage: 1
  pageSize: 25
  pagesCount: 2
  recordsCount: number
  records: Searches[] | CompanyDetailViews[] | Orders[] | CustomerRequests[]
}

export function* generateUrlSpecificPath(selectedData: SelectedDataType) {
  const selected: string = yield selectedData === SelectedDataType.Searches
    ? 'search'
    : selectedData === SelectedDataType.CompanyDetailPageViews
    ? 'companyDetail'
    : selectedData === SelectedDataType.Orders
    ? 'order'
    : selectedData === SelectedDataType.CustomerRequests
    ? 'customerRequest'
    : null

  return selected
}

export function* generateFilters(filtersScope: FilterScope) {
  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: filtersScope })
  const columnFilterkeys = _.keys(filters)
  const columnFilterKeysWithoutDate = _.filter(
    columnFilterkeys,
    f => f !== 'dateFrom' && f !== 'dateTo' && f !== 'placeDateFrom' && f !== 'placeDateTo'
  )
  const columnFilters = _.map(columnFilterKeysWithoutDate, f => filters[f])

  const dateFrom = filters.dateFrom ? moment(filters.dateFrom as Moment).format('YYYY-MM-DD') : undefined
  const dateTo = filters.dateTo
    ? moment(filters.dateTo as Moment)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    : undefined

  const placeDateFrom = filters.placeDateFrom ? moment(filters.placeDateFrom as Moment).format('YYYY-MM-DD') : undefined
  const placeDateTo = filters.placeDateTo
    ? moment(filters.placeDateTo as Moment)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    : undefined

  return {
    columnFilters,
    filters,
    dateFrom,
    dateTo,
    placeDateFrom,
    placeDateTo
  }
}

export function* loadBusinessData() {
  const selectedData: SelectedDataType = yield select(getSelectedDataType)
  const tableKey: TableKey = yield select(getTableKey)
  const filtersScope: FilterScope = yield select(getFilterScope)
  const urlSpecificPath: string = yield call(generateUrlSpecificPath, selectedData)

  if (!selectedData || !tableKey || !filtersScope || !urlSpecificPath) return

  const pagination: PaginationConfig = yield select(selectPagination, { tableKey })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey })
  const generatedFilters: {
    columnFilters: unknown[]
    dateFrom: string
    dateTo: string
    placeDateFrom: string
    placeDateTo: string
  } = yield call(generateFilters, filtersScope)
  const { data }: AxiosResponse<BusinessDataResponse> = yield call(apiCall, {
    url: `/api/${urlSpecificPath}`,
    method: 'post',
    data: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters: {
        dateFrom: generatedFilters.dateFrom,
        dateTo: generatedFilters.dateTo,
        placeDateFrom: generatedFilters.placeDateFrom,
        placeDateTo: generatedFilters.placeDateTo,
        filters: generatedFilters.columnFilters
      },
      sort: mapSortingToApi(sorting)
    }
  })

  yield put(BusinessDataActions.setData({ data: data.records }))

  yield put(
    TableActions.setPagination({
      tableKey: tableKey,
      pagination: {
        current: pagination.current,
        pageSize: data.pageSize,
        total: data.recordsCount
      }
    })
  )
}

export function* downloadData({ payload }: ReturnType<typeof BusinessDataActions.downloadData>) {
  yield downloadExportedData(payload.exportId)
}

export function* exportBusinessData({ payload }: ReturnType<typeof BusinessDataActions.makeReport>) {
  const { columns } = payload
  const selectedData: SelectedDataType = yield select(getSelectedDataType)
  const tableKey: TableKey = yield select(getTableKey)
  const filtersScope: FilterScope = yield select(getFilterScope)
  const visibleColumns: string[] = yield select(selectVisibleColumns, { tableKey })
  const urlSpecificPath: string = yield call(generateUrlSpecificPath, selectedData)
  const exportHeaders = mapExportHeaders(columns, visibleColumns)

  if (!selectedData || !tableKey || !filtersScope || !urlSpecificPath) return

  const generatedFilters: {
    columnFilters: unknown[]
    dateFrom: string
    dateTo: string
    placeDateFrom: string
    placeDateTo: string
  } = yield call(generateFilters, filtersScope)

  yield exportData(urlSpecificPath, {
    dateFrom: generatedFilters.dateFrom,
    dateTo: generatedFilters.dateTo,
    placeDateFrom: generatedFilters.placeDateFrom,
    palceDateTo: generatedFilters.placeDateTo,
    filters: generatedFilters.columnFilters,
    columns: exportHeaders
  })
}

export function* handleChangePagination(action: ReturnType<typeof TableActions.changePagination>) {
  if (
    !_.includes(
      [
        TableKey.BusinessSearchesDataList,
        TableKey.BusinessCompanyDetailDataList,
        TableKey.BusinessOrdersDataList,
        TableKey.BusinessCustomerRequestsDataList
      ],
      action.payload.tableKey
    )
  )
    return

  yield put(BusinessDataActions.loadData())
}

export function* handleChangeSorting(action: ReturnType<typeof TableActions.setSorting>) {
  if (
    !_.includes(
      [
        TableKey.BusinessSearchesDataList,
        TableKey.BusinessCompanyDetailDataList,
        TableKey.BusinessOrdersDataList,
        TableKey.BusinessCustomerRequestsDataList
      ],
      action.payload.tableKey
    )
  )
    return

  yield put(TableActions.setPage({ tableKey: action.payload.tableKey, page: 1 }))
  yield put(BusinessDataActions.loadData())
}

export function* handleChangeFilters(action: ReturnType<typeof FiltersActions.setFilters>) {
  if (
    !_.includes(
      [
        FilterScope.BusinessSearchesDataList,
        FilterScope.BusinessCompanyDetailDataList,
        FilterScope.BusinessOrdersDataList,
        FilterScope.BusinessCustomerRequestsDataList
      ],
      action.payload.scope
    )
  )
    return

  const tableKey: TableKey = yield select(getTableKey)
  if (!tableKey) return

  yield put(TableActions.setPage({ tableKey, page: 1 }))
  yield put(BusinessDataActions.loadData())
}

export function* businessDataSagas() {
  yield all([
    takeLatest(getType(BusinessDataActions.loadData), withLoading('businessData', loadBusinessData)),
    takeLatest(getType(TableActions.changePagination), handleChangePagination),
    takeLatest(getType(FiltersActions.setFilters), handleChangeFilters),
    takeLatest(getType(TableActions.setSorting), handleChangeSorting),
    takeLatest(getType(BusinessDataActions.downloadData), downloadData),
    takeLatest(getType(BusinessDataActions.makeReport), exportBusinessData)
  ])
}
