import { createSelector } from 'reselect'
import { RootState } from '../types'

const selectState = (state: RootState) => state.businessData

export const getProduct = createSelector(selectState, s => s.product)
export const getSelectedDataType = createSelector(selectState, s => s.selectedDataType)
export const getTableKey = createSelector(selectState, s => s.tableKey)
export const getFilterScope = createSelector(selectState, s => s.filterScope)
export const isBusinessDataLoading = createSelector(selectState, s => s.loading)
export const getBusinessData = createSelector(selectState, s => s.data)
