import { ActionType, createAction } from 'typesafe-actions'
import { DialogType } from './types'
import { InfoDialogData } from '../../containers/DialogRenderer/Dialogs/InfoDialog'

const showDialogWithData = createAction('SHOW_DIALOG_WITH_DATA')<{ dialog: DialogType; data: any }>()

export const DialogActions = {
  showDialog: createAction('SHOW_DIALOG')<{ dialog: DialogType }>(),
  showDialogWithData,
  setDialogData: createAction('SET_DIALOG_DATA')<{ dialog: DialogType; data: any }>(),
  updateDialogData: createAction('UPDATE_DIALOG_DATA')<{ dialog: DialogType; data: any }>(),
  closeDialog: createAction('CLOSE_DIALOG')(),
  showInfoDialog: (data: InfoDialogData) => showDialogWithData({ dialog: 'Info', data })
}

export type DialogActions = ActionType<typeof DialogActions>
