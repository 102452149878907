import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'
import _ from 'lodash'
import * as actions from './actions'
import { LoadingState, LoadingScope } from './types'

export const initialState: LoadingState = {
  loading: false,
  loadingEntities: {}
}

export type WithLoading<T> = T & LoadingState

export const withLoadingReducer = <T>(
  reducer: Reducer<any, any>,
  scope: LoadingScope,
  wrapeeInitial: T
): Reducer<WithLoading<T>, ActionType<typeof actions>> => {
  return (state = { ...wrapeeInitial, ...initialState }, action) => {
    if (scope !== _.get(action, 'payload.scope')) return reducer(state, action)

    switch (action.type) {
      case 'SET_LOADING':
        if (action.payload.entityId) {
          return {
            ...state,
            loadingEntities: {
              ...state.loadingEntities,
              [action.payload.entityId]: action.payload.loading
            }
          }
        }
        return { ...state, loading: action.payload.loading }
      default:
        return reducer(state, action)
    }
  }
}
