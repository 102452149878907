import React, { useRef, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { getToken, isUserLoggedIn } from '../../redux/auth/selectors'
import { TokenPayload, AuthActions } from '../../redux/auth'
import { useInterval } from '../../hooks/useInterval'
import { Routes } from '../../utils/routes'
import _ from 'lodash'
import { Idle } from '../Idle'
import { DialogActions } from '../../redux/dialog'
import { getAutoLogoutTime } from '../../redux/config'
import { SignalR } from '../../utils/signalr'
import { NotificationActions } from '../../redux/notifications'

function decodeToken(token: string | null): TokenPayload | null {
  try {
    return token ? jwtDecode(token) : null
  } catch {
    return null
  }
}

function useTokenChecking(dispatch: Dispatch) {
  const token = useSelector(getToken)

  const decodedToken = useRef<TokenPayload | null>(decodeToken(token))

  useEffect(() => {
    decodedToken.current = decodeToken(token)
  }, [token])

  const checkToken = () => {
    if (!decodedToken.current) return
    const expiration = moment.unix(decodedToken.current.exp)
    const diff = expiration.diff(moment(), 's')

    if (diff < 0) {
      dispatch(AuthActions.logout())
      return
    }
    if (diff < 60) dispatch(AuthActions.refreshToken())
  }

  useEffect(() => {
    checkToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(checkToken, 15000)
}

type Props = {
  component: React.ElementType
} & RouteProps

export const AuthRoute = ({ component: Component, ...rest }: Props) => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(isUserLoggedIn)
  const logoutTime = useSelector(getAutoLogoutTime)

  useTokenChecking(dispatch)

  useEffect(() => {
    if (loggedIn && !SignalR.isConnected()) {
      dispatch(NotificationActions.startSignalR())
    }
  }, [loggedIn, dispatch])

  return (
    <Route
      {...rest}
      render={props => {
        if (!loggedIn) {
          return (
            <Redirect
              to={{
                pathname: Routes.Login,
                state: { from: props.location }
              }}
            />
          )
        }

        return _.gt(logoutTime, 0) ? (
          <Idle
            timeout={logoutTime * 1000}
            onChange={(inactive: boolean) => {
              if (!inactive) return
              dispatch(
                DialogActions.showDialog({
                  dialog: 'LogoutCancel'
                })
              )
            }}
            render={() => <Component {...props} />}
          />
        ) : (
          <Component {...props} />
        )
      }}
    />
  )
}
