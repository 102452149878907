import { getType, Reducer } from 'typesafe-actions'
import { withLoadingReducer } from '../loading'

import { UserActions } from './actions'
import { UserState } from './types'

export const initialState: UserState = {}

export const reducer: Reducer<UserState, UserActions> = (state = initialState, action) => {
  if (action.type === getType(UserActions.setUserDetail)) {
    return {
      userDetail: {
        ...action.payload.userDetail
      }
    }
  }
  return state
}
export const userReducer = withLoadingReducer(reducer, 'userDetail', initialState)
