import _ from 'lodash'
import { Dispatch } from 'redux'

import { addEvents } from '../../utils/eventr'

import { HubEvent } from './types'

export const mapNotificationResponse = (notification: any): HubEvent => {
  const { timestamp, ...rest } = notification
  return {
    ...rest,
    dateTime: timestamp
  }
}

export const createHubEventHandler = (dispatch: Dispatch) => (...notificationResponse: any) => {
  dispatch(addEvents(_.map(notificationResponse, mapNotificationResponse)))
}
