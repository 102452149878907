export default {
  UserDetails: 'User details',
  EditUser: 'Edit user',
  UserUpdated: 'User successfully updated!',
  UserUpdateError: 'User could not be updated',
  UserAliasTable: {
    CreateAliasButton: 'Create Alias',
    UserAliasColumn: 'User Alias',
    SSO: 'SSO',
    IsActiveColumn: 'Is Active',
    LoginAsColumn: 'Login As',
    UserTypeColumn: 'User Type',
    DeleteButton: 'Delete',
    EditButton: 'Edit'
  },
  UserAliasDialogs: {
    CreateOrUpdateDialog: {
      CreateUserAliasTitle: 'Create Alias',
      UpdateUserAliasTitle: 'Update Alias',
      Options: 'Margo user type',
      Alias: 'Margo Alias',
      SSO: 'SSO Code',
      CreateButton: 'Create',
      UpdateButton: 'Update',
      CancelButton: 'Cancel'
    },
    DeleteDialog: {
      title: 'Delete Alias',
      ErrorText: 'User alias cannot be deleted, try again later',
      PromptText: 'Do you want to delete user alias: \n\r {{userAlias}}?',
      DeleteButton: 'Delete',
      CancelButton: 'Cancel'
    }
  },
  UserPartialUpdatedTitle: 'Partial success',
  UserPartialUpdatedText:
    'User successfully updated but alias user consumption limits update failed! Please update them manually.',
  UserPartialDeletedTitle: 'Partial success',
  UserPartialDeletedText:
    'User {{userId}} is deleted but user consumption limits failed to delete! Please delete them manually.'
}
