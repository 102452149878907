import { ActionType, createAction } from 'typesafe-actions'
import { ColumnType } from '../../components/Table'
import { SelectedDataType, BusinessData } from './types'

export const BusinessDataActions = {
  selectData: createAction('BUSINESS-DATA/select-data')<{ product: string; selectedDataType: SelectedDataType }>(),
  loadData: createAction('BUSINESS-DATA/load-data')(),
  setData: createAction('BUSINESS-DATA/set-data')<{ data: BusinessData }>(),
  resetData: createAction('BUSINESS-DATA/reset-data')(),
  downloadData: createAction('BUSINESS-DATA/download-data')<{ exportId: string }>(),
  makeReport: createAction('BUSINESS-DATA/make-report')<{ columns: ColumnType[] }>()
}

export type BusinessDataActions = ActionType<typeof BusinessDataActions>
