import _ from 'lodash'
import { SelectOption } from '../components/Select'
import { UserRole, ClaimCode, AuthorizedSelectOption } from '../redux/auth'
import { SelectResponse } from './types'

export function formatRolesForDisplay(roles: string[] | undefined, roleOptions?: SelectResponse) {
  if (!roleOptions) return _.join(roles, ', ')
  const rolesDict = _.keyBy(roleOptions?.values, x => x.value)

  return _.join(
    _.map(roles, x => rolesDict[x].key),
    ', '
  )
}

function areSelectOptions(upperRoles?: string[] | SelectOption[]): upperRoles is SelectOption[] {
  if (!upperRoles || upperRoles.length == 0) return false
  return !!(upperRoles as SelectOption[])[0]?.value
}

export const isFromMargo = (
  roles: string[],
  upperRoles?: string[] | SelectOption[],
  margoRoles: UserRole[] = [UserRole.MargoID, UserRole.MargoPH, UserRole.MargoCH, UserRole.MargoAT]
) => {
  if (areSelectOptions(upperRoles)) {
    return _.some(margoRoles, x => !!_.find(roles, r => x == r) || !!_.find(upperRoles, r => r.value === x))
  }

  return _.some(margoRoles, x => !!_.find(roles, r => x == r) || !!_.includes(upperRoles, x))
}

export function margoEnabled(enabledProducts?: string[]) {
  return _.includes(enabledProducts, 'Margo')
}

export const isAuthorizedByAllClaims = (userClaims?: ClaimCode[], requiredClaims?: ClaimCode[]) => {
  return !requiredClaims || _.every(requiredClaims, requiredClaim => _.includes(userClaims || [], requiredClaim))
}

export const isAuthorizedByAnyClaim = (userClaims?: ClaimCode[], requiredClaims?: ClaimCode[]) => {
  return !requiredClaims || _.some(requiredClaims, requiredClaim => _.includes(userClaims || [], requiredClaim))
}

export const isAuthorizedByAllRoles = (userRoles?: UserRole[], requiredRoles?: UserRole[]) => {
  return !requiredRoles || _.every(requiredRoles, requiredRole => _.includes(userRoles || [], requiredRole))
}

export const isAuthorizedByAnyRole = (userRoles?: UserRole[], requiredRoles?: UserRole[]) => {
  return !requiredRoles || _.some(requiredRoles, requiredRole => _.includes(userRoles || [], requiredRole))
}

export const isAuthorizedByClaims = (
  userClaims?: ClaimCode[],
  requiredClaims?: ClaimCode[],
  condition?: 'All' | 'Any'
) => {
  return condition === 'Any'
    ? isAuthorizedByAnyClaim(userClaims, requiredClaims)
    : isAuthorizedByAllClaims(userClaims, requiredClaims)
}

export const isAuthorizedByRoles = (userRoles?: UserRole[], requiredRoles?: UserRole[], condition?: 'All' | 'Any') => {
  return condition === 'Any'
    ? isAuthorizedByAnyRole(userRoles, requiredRoles)
    : isAuthorizedByAllRoles(userRoles, requiredRoles)
}

export const isSalesOrganizationForbidden = (
  salesOrganizationName?: string,
  forbiddenSalesOrganizationNames?: string[]
) => {
  return (
    salesOrganizationName &&
    forbiddenSalesOrganizationNames &&
    _.some(forbiddenSalesOrganizationNames, x => x === salesOrganizationName)
  )
}

export const isSalesOrganizationAllowed = (salesOrganizationName?: string, allowedSalesOrganization?: string[]) => {
  return (
    !allowedSalesOrganization ||
    (salesOrganizationName && _.some(allowedSalesOrganization, x => x === salesOrganizationName))
  )
}

export const mapAuthorizedSelectOption = (options: AuthorizedSelectOption[], salesOrganizationName?: string) => {
  return _.map(
    _.filter(options, co => _.some(co.allowedSalesOrganizations, aso => aso === salesOrganizationName)),
    c => {
      return {
        label: c.label,
        value: c.value
      }
    }
  )
}
