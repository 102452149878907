import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { LastLocationContext, LastLocationType } from './context'
import { Assign } from '../../utils/types'

type UpdateLastLocation = {
  location: LastLocationType
  nextLocation: RouteComponentProps['location']
  watchOnlyPathname: boolean
}

const getLastLocation = ({ location, nextLocation, watchOnlyPathname }: UpdateLastLocation) => {
  if (location === null) {
    return location
  }

  if (nextLocation === location) {
    return location
  }

  if (watchOnlyPathname && location.pathname === nextLocation.pathname) {
    return location
  }

  return { ...location }
}

interface Props extends RouteComponentProps {
  watchOnlyPathname: boolean
  children: React.ReactNode
}

type State = Readonly<{
  currentLocation: LastLocationType
  lastLocation: LastLocationType
}>

class LastLocationProviderComponent extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    return {
      currentLocation: props.location,
      lastLocation: getLastLocation({
        location: state.currentLocation,
        nextLocation: props.location,
        watchOnlyPathname: props.watchOnlyPathname
      })
    }
  }

  readonly state: State = {
    currentLocation: null,
    lastLocation: null
  }

  render() {
    const { children } = this.props
    const { lastLocation } = this.state
    return <LastLocationContext.Provider value={lastLocation}>{children}</LastLocationContext.Provider>
  }
}

type ExportedProps = Assign<Pick<Props, Exclude<keyof Props, 'watchOnlyPathname'>>, { watchOnlyPathname?: boolean }>

export const LastLocationProvider = withRouter(LastLocationProviderComponent as React.ComponentClass<ExportedProps>)
