import { Dictionary } from '../../utils/types'

export enum FilterScope {
  UserList = 'UserList',
  RoleList = 'RoleList',
  SearchOrganizations = 'SearchOrganizations',
  TransactionList = 'TransactionList',
  BusinessSearchesDataList = 'BusinessSearchesDataList',
  BusinessCompanyDetailDataList = 'BusinessCompanyDetailDataList',
  BusinessOrdersDataList = 'BusinessOrdersDataList',
  BusinessCustomerRequestsDataList = 'BusinessCustomerRequestsDataList',
  AccessControlBlockedSitesDataList = 'AccessControlBlockedSitesDataList',
  AccessControlBlockedUsersDataList = 'AccessControlBlockedUsersDataList',
  AccessControlWhitelistDataList = 'AccessControlWhitelistDataList'
}

export type FiltersState = Record<
  FilterScope,
  {
    filters: Dictionary<unknown>
  }
>
