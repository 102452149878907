import { ActionType, createAction } from 'typesafe-actions'
import { Dictionary } from '../../utils/types'
import { FilterScope } from './types'
import { ConfigActions } from '../config'

export const FiltersActions = {
  setFilters: createAction('FILTERS/set-filters')<{ scope: FilterScope; filters: Dictionary<unknown> }>()
}

export type FiltersActions = ActionType<typeof FiltersActions | typeof ConfigActions>
