import _ from 'lodash'
import axios from 'axios'
import { AxiosResponse } from 'axios'
import { put, select, call } from 'redux-saga/effects'
import { apiCall } from './api'
import { DialogType } from '../redux/dialog/types'
import { DialogActions } from '../redux/dialog'
import { getVisibleDialog } from '../redux/dialog/selectors'
import { ColumnType } from '../components/Table'

export function* exportData(path: string, data: any) {
  try {
    yield call(apiCall, {
      url: `/api/export/${path}`,
      method: 'post',
      data: data
    })
  } catch (e) {
    const visibleDialog: DialogType = yield select(getVisibleDialog)
    if (visibleDialog === 'DownloadData')
      yield put(
        DialogActions.updateDialogData({
          dialog: 'DownloadData',
          data: {
            isError: true
          }
        })
      )
    else {
      yield put(DialogActions.closeDialog())
      yield put(
        DialogActions.showDialogWithData({
          dialog: 'DownloadData',
          data: {
            isError: true
          }
        })
      )
    }
  }
}

export function mapExportHeaders(columns: ColumnType[], visibleColumns: string[]) {
  const columnsToExport = _.some(visibleColumns)
    ? _.filter(columns, c => _.some(visibleColumns, vc => c.id === vc))
    : columns
  return _.map(columnsToExport, cte => {
    return { id: cte.id, name: cte.title }
  })
}

export function* downloadExportedData(exportId: string) {
  const {
    data
  }: AxiosResponse<{
    token: string
  }> = yield call(apiCall, {
    url: '/api/Export/token',
    method: 'get',
    params: {
      id: exportId
    }
  })

  openInNewTab(`${axios.defaults.baseURL}/api/Export?id=${exportId}&token=${data.token}`, document.body)
}

function openInNewTab(url: string, hostElement: HTMLElement) {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  hostElement.appendChild(a)
  a.click()
  hostElement.removeChild(a)
}
