import _ from 'lodash'
import { AxiosResponse } from 'axios'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { apiCall } from '../../utils/api'
import { Routes } from '../../utils/routes'
import { DialogActions } from '../dialog'
import { withLoading } from '../loading'
import { SubscriberActions } from './actions'
import { SalesOrganizationProfileCode } from '../types'

export type SubscriptionResponse = {
  id: string
  billingType: string
  cleaningStatus: string
  hasAnalyticsIndexes: boolean
  hasAutomaticRenewal: boolean
  hasBaseList: boolean
  hasCompleteList: boolean
  hasLocalUnit: boolean
  hasMarketingList: boolean
  hasPdfReport: boolean
  hasRetailIndexes: boolean
  organizationId: string
  subscriptionDetails?: {
    hasAdvertising: boolean
    hasPortfolioActions: boolean
    hasPortfolioManager: boolean
    hasProspecting: boolean
    hasSaleTool: boolean
    hasTargeting: boolean
    id: string
    subscriptionId: string
    userType: string
  }[]
  subscriptionEndDate: string
  subscriptionStartDate: string
  subscriptionCompanyVisibility?: {
    id: string
    companyVisibilityId: string
    subscriptionId: string
    companyVisibility: {
      id: string
      description: string
      filter: string
    }[]
  }[]
  consumptionLimit?: {
    id: string
    additionalAmount: string
    consumedAdditionalAmount: string
    consumedAmount: string
    consumptionType: string
    distinguishName: string
    initialAmount: string
    subscriptionId: string
    totalAmount: string
  }
  subscriptionModuleFields?: {
    id: string
    moduleFieldId: string
    moduleField: {
      id: string
      name: string
    }
  }[]
  consumptionLimitAddons?: {
    id: string
    amount: number
    consumptionType: string
    createdAt: string
    productCode: string
  }[]
  subscriptionRoles: {
    id: string
    roleId: string
    role: {
      id: string
      code: string
      description: string
    }
    subscriptionId: string
  }[]
}

export function* fetchIfMargoSubscriptionExists({
  payload
}: ReturnType<typeof SubscriberActions.margoManagementInitPopup>) {
  const { organizationId, subscriberId } = payload
  const { data }: AxiosResponse<SubscriptionResponse> = yield call(apiCall, {
    url: '/api/Subscription',
    method: 'get',
    params: {
      subscriberId
    }
  })

  const subscription = data

  if (!subscription) {
    yield put(
      DialogActions.showDialogWithData({
        dialog: 'MargoNotActiveSubscription',
        data: {
          subscriberId,
          organizationId
        }
      })
    )
  } else {
    yield put(
      push(
        generatePath(Routes.MargoSubscriptionEdit, {
          subscriberId: subscriberId,
          subscriptionId: subscription.id,
          organizationId
        })
      )
    )
  }
}

export function* margoSubscriptionPostProcess({
  payload
}: ReturnType<typeof SubscriberActions.margoSubscriptionPostProcess>) {
  const {
    mode,
    subscriptionLicenseRequest,
    subscriberDetail: subscriber,
    maxUsersPurchased,
    previousLicenseCode
  } = payload
  let isError = false

  try {
    if (
      _.isString(subscriptionLicenseRequest?.licenseCode) &&
      (subscriptionLicenseRequest?.licenseCode?.length ?? 0) > 0
    ) {
      yield call(apiCall, {
        url: '/api/SubscriptionLicense',
        method: 'PUT',
        data: subscriptionLicenseRequest
      })
    } else if (_.isString(previousLicenseCode) && (previousLicenseCode?.length ?? 0) > 0) {
      yield call(apiCall, {
        url: '/api/SubscriptionLicense',
        method: 'DELETE',
        params: { subscriptionId: subscriptionLicenseRequest?.subscriptionId }
      })
    }
  } catch {
    isError = true
    if (mode === 'create') {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:CreateSubscriptionDialogLicenseError:Title',
          i18nText: 'margo:CreateSubscriptionDialogLicenseError:Description',
          namespaces: ['margo']
        })
      )
    } else {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:EditSubscriptionDialogLicenseError:Title',
          i18nText: 'margo:EditSubscriptionDialogLicenseError:Description',
          namespaces: ['margo']
        })
      )
    }
  }

  try {
    if (maxUsersPurchased !== subscriber?.maxUsersPurchased) {
      yield call(apiCall, {
        url: `/api/subscribers/${
          subscriber?.adminXSubscriber?.salesOrganizationProfileCode === SalesOrganizationProfileCode.ManualAccounting
            ? 'update'
            : 'import'
        }`,
        method: 'put',
        data: {
          subscriberId: subscriber?.subscriberId,
          companyName: subscriber?.companyName,
          statusCode: subscriber?.adminXSubscriber?.subscriberStatus,
          publicId: subscriber?.publicId,
          salesOrganizationId: subscriber?.adminXSubscriber?.salesOrganizationId,
          entityType: subscriber?.adminXSubscriber?.entityType,
          registrationType: subscriber.adminXSubscriber?.registrationType,
          registrationChannel: subscriber?.adminXSubscriber?.registrationChannel,
          privacyFlag: subscriber?.adminXSubscriber?.privacyFlag,
          termsConditionsFlag: subscriber?.adminXSubscriber?.termsConditionsFlag,
          myDocExpirationDays: subscriber?.adminXSubscriber?.myDocExpirationDays,
          customerDetails: subscriber?.adminXSubscriber?.customerDetails,
          customerNotes: subscriber?.adminXSubscriber?.customerNotes,
          crifNumber: subscriber?.adminXSubscriber?.crifNumber,
          billingEmailAddress: subscriber?.billingEmailAddress,
          customerAccounting: {
            subscriberId: subscriber?.sapSubscriberId,
            subscriberCode: subscriber?.subscriberCode,
            referenceCode: subscriber?.referenceCode,
            customerId: subscriber?.sapCustomerId
          },
          address: {
            address: subscriber?.address,
            city: subscriber?.city,
            countryCode: subscriber?.countryCode,
            stateCode: subscriber?.stateCode,
            zipCode: subscriber?.zipCode
          },
          creationDate: subscriber?.adminXSubscriber?.creationDate,
          expirationDate: subscriber?.adminXSubscriber?.expirationDate,
          paymentOption: subscriber?.adminXSubscriber?.paymentOptionCode,
          roles: subscriber?.adminXSubscriber?.roles,
          maxUsersPurchased
        }
      })
    }
  } catch {
    isError = true
    if (mode === 'create') {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:CreateSubscriptionDialogMaxUsersError:Title',
          i18nText: 'margo:CreateSubscriptionDialogMaxUsersError:Description',
          namespaces: ['margo']
        })
      )
    } else {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:EditSubscriptionDialogMaxUsersError:Title',
          i18nText: 'margo:EditSubscriptionDialogMaxUsersError:Description',
          namespaces: ['margo']
        })
      )
    }
  }

  if (!isError) {
    if (mode === 'create') {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:CreateSubscriptionDialogSuccess:Title',
          i18nText: 'margo:CreateSubscriptionDialogSuccess:Description',
          namespaces: ['margo']
        })
      )
    } else {
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'margo:EditSubscriptionDialogSuccess:Title',
          i18nText: 'margo:EditSubscriptionDialogSuccess:Description',
          namespaces: ['margo']
        })
      )
    }
  }

  yield put(push(Routes.SearchOrganizations))
}

export function* subscriberSagas() {
  yield all([
    takeLatest(
      getType(SubscriberActions.margoManagementInitPopup),
      withLoading('margoSubscriber', fetchIfMargoSubscriptionExists)
    ),
    takeLatest(
      getType(SubscriberActions.margoSubscriptionPostProcess),
      withLoading('margoSubscriber', margoSubscriptionPostProcess)
    )
  ])
}
