import styled, { css } from 'styled-components/macro'
import { Box } from '../Box'

export const centerChildrenMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CenterChildren = styled(Box)`
  ${centerChildrenMixin}
  width: 100%;
`
