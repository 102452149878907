import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import persistReducer from 'redux-persist/es/persistReducer'
import localStorage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import { createEventRReducer } from 'react-eventr'
import { logReducer } from './logs'
import { authReducer } from './auth'
import { userReducer } from './user'
import { subscriberReducer } from './subscriber'
import { TableActions, TableState, reducer as tableReducer } from './table'
import { userListReducer } from './userList'
import { transactionListReducer } from './transactionList'
import { FiltersActions, FiltersState, filtersReducer } from './filters'
import { dialogReducer } from './dialog'
import { roleListReducer } from './roleList'
import { configReducer } from './config'
import { businessDataReducer } from './businessData'
import { accessControlReducer } from './accessControl'
import { sapAsyncReducer } from './sapAsync'

const persistedUserReducer = persistReducer(
  {
    key: 'user',
    whitelist: ['token', 'claims'],
    storage: sessionStorage
  },
  authReducer
)

const persistedTableReducer = persistReducer<TableState, TableActions>(
  {
    key: 'table',
    storage: localStorage,
    stateReconciler: autoMergeLevel1
  },
  tableReducer
)

const persistedFiltersReducer = persistReducer<FiltersState, FiltersActions>(
  {
    key: 'filters',
    storage: localStorage,
    stateReconciler: autoMergeLevel1
  },
  filtersReducer
)

export const makeRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    logs: logReducer,
    auth: persistedUserReducer,
    user: userReducer,
    table: persistedTableReducer,
    userList: userListReducer,
    transactionList: transactionListReducer,
    filters: persistedFiltersReducer,
    dialog: dialogReducer,
    roleList: roleListReducer,
    subscriber: subscriberReducer,
    config: configReducer,
    businessData: businessDataReducer,
    accessControl: accessControlReducer,
    sapAsync: sapAsyncReducer,
    eventr: createEventRReducer(20)
  })
