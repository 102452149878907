export default {
  CreateRole: 'Create role',
  RoleName: 'Role name',
  CreatedBy: 'Created by',
  RoleDetail: 'Role detail',
  EditRole: 'Edit role {{roleName}}',
  Description: 'Description',
  AssociatedAuthorizations: 'Associated authorizations',
  AuthorizationCode: 'Authorization Code "Value"',
  AuthorizationDescription: 'Authorization Description',
  AssignAuthorizations: 'Assign authorizations',
  AssignedAuthorizations: 'Assigned authorizations',
  UnassignedAuthorizations: 'Unassigned authorizations',
  DeleteRole: {
    Title: 'Delete role',
    Text: 'Are you sure that you want to remove role {{roleName}}?',
    Success: 'Role {{roleName}} was successfully deleted.'
  },
  AddRoleDialog: {
    Success: {
      Title: 'Success',
      Text: 'Role {{roleName}} has been successfully created.'
    },
    Error: {
      Title: 'Error',
      Text: 'There was error while creating role {{roleName}}. Please try again later.'
    }
  },
  EditRoleDialog: {
    Success: {
      Title: 'Success',
      Text: 'Role {{roleName}} has been successfully modified.'
    },
    Error: {
      Title: 'Error',
      Text: 'There was error while modifying role {{roleName}}. Please try again later.'
    }
  },
  BackToRoleList: 'Back to Role list',
  BadRequestTitle: 'Error',
  BadRequestMessage:
    'Something went wrong during the operation execution. Please, try to clear your browser cache and login again. Contact development team to report the issue'
}
