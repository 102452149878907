export default {
  CompanyVisibility: 'Company Visibility',
  PortfolioManagement: 'Portfolio Management',
  Targeting: 'Targeting',
  Prospecting: 'Prospecting',
  SalesTool: 'Sales Tool',
  Supervisor: 'Supervisor',
  Manager: 'Manager',
  Sales: 'Sales',
  Base: 'Base',
  Marketing: 'Marketing',
  CompleteCustom: 'Complete/Custom',
  TotalAmountForEnrichment: 'Total amount for Enrichment',
  InitialAmountForEnrichment: 'Initial amount for Enrichment',
  ConsumedAmountForEnrichment: 'Consumed amount for Enrichment',
  DataPacketCustomIndicators: 'Data Packet - Custom Indicators',
  AddonEnrichment: 'Add on Enrichment',
  Modules: 'Modules',
  MarketingList: 'Marketing list',
  Transaction: 'Transaction',
  DownloadSubscription: 'Download list of offices and active users',
  NotActiveSubscribtionDialog: {
    Title: 'No active subscription',
    Description: "There isn't any active subscription. Do you want to create a new subscription?"
  },
  CreateSubscriptionDialogSuccess: {
    Title: 'Success',
    Description: 'Subscription was successfully created'
  },
  CreateSubscriptionDialogError: {
    Title: 'Error',
    Description: 'There was error while creating subscription, please try again later.'
  },
  CreateSubscriptionDialogLicenseError: {
    Title: 'Error',
    Description: 'Subscription was successfully created but license information update failed.'
  },
  CreateSubscriptionDialogMaxUsersError: {
    Title: 'Error',
    Description: 'Subscription was successfully created but maximum users limit update failed.'
  },
  EditSubscriptionDialogSuccess: {
    Title: 'Success',
    Description: 'Subscription was successfully modified.'
  },
  EditSubscriptionDialogError: {
    Title: 'Error',
    Description: 'There was error while editing of subscription, please try again later.'
  },
  EditSubscriptionDialogLicenseError: {
    Title: 'Error',
    Description: 'Subscription was successfully modified but license information update failed.'
  },
  EditSubscriptionDialogMaxUsersError: {
    Title: 'Error',
    Description: 'Subscription was successfully modified but maximum users limit update failed.'
  },
  OfficeConsumptionLimitDialog: {
    Title: 'Office - consumption limit',
    Success: 'Office consumption limits were succesfully modified.',
    Error: 'There was error while modifying office consumption limits. Please try again later.'
  },
  UserConsumptionLimitDialog: {
    Title: 'User - consumption limit',
    Success: 'User consumption limits were succesfully modified.',
    Error: 'There was error while modifying user consumption limits. Please try again later.'
  },
  HistoricalData: 'Data packet - Historical Data',
  License: 'License',
  AutomaticRenewal: 'Automatic renewal',
  MaxUsersPurchased: 'Max users purchased',
  NumberOfActiveUsers: 'No. of Activated Users',
  ChangeConsumption: 'Change consumption'
}
