import { createSelector } from 'reselect'
import { RootState } from '../types'

const selectState = (state: RootState) => state.accessControl

export const getProduct = createSelector(selectState, s => s.product)
export const getSelectedDataType = createSelector(selectState, s => s.selectedDataType)
export const isAccessControlDataLoading = createSelector(selectState, s => s.loading)
export const getTableKey = createSelector(selectState, s => s.tableKey)
export const getAccessControlData = createSelector(selectState, s => s.data)
export const getFilterScope = createSelector(selectState, s => s.filterScope)
