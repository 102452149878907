import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../types'

const selectState = (state: RootState) => state.dialog

const getDialogData = createSelector(selectState, state => state.dialogData)

export const getVisibleDialog = createSelector(selectState, state => state.visibleDialog)

export const getVisibleDialogData = createSelector(getDialogData, getVisibleDialog, (dialogData, visibleDialog) =>
  _.get(dialogData, visibleDialog as string)
)
