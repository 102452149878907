import { ActionType, createAction } from 'typesafe-actions'
import { LoadingScope } from './types'

const setLoading = createAction('SET_LOADING')<{
  scope: LoadingScope
  loading: boolean
  entityId?: string
}>()

export const LoadingActions = {
  setLoading
}

export type LoadingActions = ActionType<typeof LoadingActions>
