import { getType } from 'typesafe-actions'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { RoleListActions } from './actions'
import { withLoading } from '../loading'
import { PaginationConfig, TableActions, TableKey } from '../table'
import { selectPagination, selectSorting } from '../table/selectors'
import { FiltersActions, FilterScope, getFilterValues } from '../filters'
import { Dictionary } from '../../utils/types'
import { ColumnSorting } from '../../components/Table'
import { mapSortingToApi } from '../../utils/helpers'
import axios, { AxiosResponse } from 'axios'
import { apiCall } from '../../utils/api'
import { DialogActions } from '../../redux/dialog'

type RoleResponse = {
  currentPage: number
  pageSize: number
  pagesCount: number
  recordsCount: number
  records: {
    identityRoleId: string
    description: string
    name: string
    createdBy: {
      userName: string
      firstName: string
      surname: string
    }
    creationDate: string
    lastUpdateDate: string
  }[]
}

export function* loadRoles() {
  const pagination: PaginationConfig = yield select(selectPagination, { tableKey: TableKey.RoleList })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey: TableKey.RoleList })
  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: FilterScope.RoleList })

  let response: AxiosResponse<RoleResponse>

  try {
    response = yield call(apiCall, {
      url: `/api/roles`,
      method: 'post',
      data: {
        page: pagination.current,
        pageSize: pagination.pageSize,
        filters: { ...filters },
        sort: mapSortingToApi(sorting)
      }
    })
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400)
      yield put(
        DialogActions.showInfoDialog({
          i18nTitle: 'role:BadRequestTitle',
          i18nText: 'role:BadRequestMessage',
          namespaces: ['role']
        })
      )

    return
  }

  const data = response.data

  yield put(RoleListActions.setRoles({ roles: data.records }))

  yield put(
    TableActions.setPagination({
      tableKey: TableKey.RoleList,
      pagination: {
        current: pagination.current,
        pageSize: data.pageSize,
        total: data.recordsCount
      }
    })
  )
}

export function* handleChangePagination(action: ReturnType<typeof TableActions.changePagination>) {
  if (action.payload.tableKey !== TableKey.RoleList) return

  yield put(RoleListActions.loadRoles())
}

export function* handleChangeSorting(action: ReturnType<typeof TableActions.setSorting>) {
  if (action.payload.tableKey !== TableKey.RoleList) return
  yield put(TableActions.setPage({ tableKey: TableKey.RoleList, page: 1 }))
  yield put(RoleListActions.loadRoles())
}

export function* handleChangeFilters(action: ReturnType<typeof FiltersActions.setFilters>) {
  if (action.payload.scope !== FilterScope.RoleList) return

  yield put(TableActions.setPage({ tableKey: TableKey.RoleList, page: 1 }))
  yield put(RoleListActions.loadRoles())
}

export function* roleListSagas() {
  yield all([
    takeLatest(getType(RoleListActions.loadRoles), withLoading('roleList', loadRoles)),
    takeLatest(getType(TableActions.changePagination), handleChangePagination),
    takeLatest(getType(FiltersActions.setFilters), handleChangeFilters),
    takeLatest(getType(TableActions.setSorting), handleChangeSorting)
  ])
}
