import { ActionType, createAction } from 'typesafe-actions'

export const SapAsyncActions = {
  loadSapAvailability: createAction('SAP-ASYNC/load-sap-availability')(),
  setSapAvailability: createAction('SAP-ASYNC/set-sap-availability')<{
    isDiscoveryConfigured: boolean | undefined
    isOnline: boolean | undefined
  }>()
}

export type SapAsyncActions = ActionType<typeof SapAsyncActions>
