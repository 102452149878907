import { ActionType, createAction } from 'typesafe-actions'
import { BriqConfigState, ConfigState } from './types'

export const ConfigActions = {
  setConfig: createAction('CONFIG/set-config')<ConfigState>(),
  loadBriqConfig: createAction('CONFIG/load-briq-config')(),
  setBriqConfig: createAction('CONFIG/set-briq-config')<BriqConfigState>()
}

export type ConfigActions = ActionType<typeof ConfigActions>
