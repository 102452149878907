import { getType, Reducer } from 'typesafe-actions'
import { withLoadingReducer } from '../loading'

import { SubscriberActions } from './actions'
import { SubscriberState } from './types'

export const initialState: SubscriberState = {}

export const reducer: Reducer<SubscriberState, SubscriberActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(SubscriberActions.setSubscriberDetail):
      return {
        ...state,
        subscriberDetail: action.payload
          ? {
              ...action.payload.data
            }
          : undefined
      }
    case getType(SubscriberActions.setSalesOrganization):
      return {
        ...state,
        salesOrganizationId: action.payload?.salesOrganizationId
      }
    default:
      return state
  }
}

export const subscriberReducer = withLoadingReducer(reducer, 'margoSubscriber', initialState)
