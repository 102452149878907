import { ActionType, createAction } from 'typesafe-actions'
import { UserDetailResponse } from './types'
import { AxiosRequestConfig } from 'axios'

export const UserActions = {
  fetchUserDetail: createAction('USER/fetch-user-detail')<{ userId: string }>(),
  setUserDetail: createAction('USER/set-user-detail')<{ userDetail: UserDetailResponse }>(),
  postProcessUserEdit: createAction('USER/post-process-user-edit')<{
    userId: string
    closeAfterSubmit: boolean
    postBulkConsumptionDetailsConfig: AxiosRequestConfig | undefined
    refreshConsumptionLimits: () => void
  }>()
}

export type UserActions = ActionType<typeof UserActions>
