import { getType } from 'typesafe-actions'
import { all, call, select, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { SignalR } from '../../utils/signalr'
import { getToken } from '../auth/selectors'
import { NotificationActions } from './actions'
import { createHubEventHandler } from './handler'

export function* startSignalR() {
  const token: string | undefined = yield select(getToken)
  const isConnected: boolean = yield call(SignalR.isConnected)
  if (!token || isConnected) return

  yield call(SignalR.start, axios.defaults.baseURL || '')

  yield call(subscribe)
}

export function* subscribe() {
  yield call(SignalR.addSubscriptionHandler, 'Notification', createHubEventHandler)
}

/* istanbul ignore next */
export function* notificationSagas() {
  yield all([takeEvery(getType(NotificationActions.startSignalR), startSignalR)])
}
