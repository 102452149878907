export default {
  SubscriberCreatedTitle: 'Subscriber has been successfully created!',
  CreateSubscriber: 'Create subscriber',
  CreateSubscriberAndUser: 'Create subscriber and user',
  ConfirmSapData: 'Confirm user data from SAP',
  SapSubscriberDetail: 'SAP subscriber detail',
  SubscriberExistsTitle: 'Subscriber already exists in database!',
  SubscriberExistsText: 'You can edit subscriber after confirming data below',
  UpdateSubscriber: 'Update subscriber',
  SubscriberUpdatedTitle: 'Success!',
  SubscriberUpdatedText: 'Subscriber has been successfully updated!',
  SubscriberNotFound: 'Subscriber not found',
  Subscription: 'Subscription',
  SubscriberCreatedText: 'Subscriber has been successfully created!',
  SubscriberCreatedSuccessTitle: 'Success!'
}
