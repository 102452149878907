import * as signalR from '@microsoft/signalr'
import { LogLevel } from '@microsoft/signalr'
import { Dispatch, Store } from 'redux'

import { LogActions } from '../redux/logs/actions'
import { getToken } from '../redux/auth/selectors'

import { mapActionError, wait } from './helpers'
import _ from 'lodash'

async function startConnection(dispatch: any, connection: signalR.HubConnection, reconnectTimeoutCounter = 0) {
  const realtimeReconnectTimeouts = [1000, 5000, 10000, 20000, 30000]
  try {
    await connection.start()
  } catch (e) {
    if (reconnectTimeoutCounter > realtimeReconnectTimeouts.length) {
      const error: any = e
      dispatch(
        LogActions.addLogItem(
          mapActionError(
            {
              message: _.isString(error) ? error : error.message,
              stack: _.isString(error) ? undefined : error.stack,
              isAxiosError: false
            },
            'ERROR'
          )
        )
      )
    } else {
      await wait(realtimeReconnectTimeouts[reconnectTimeoutCounter])
      await startConnection(dispatch, connection, reconnectTimeoutCounter + 1)
    }
  }
}

export class SignalR {
  private static connection: signalR.HubConnection

  private static store: Store

  static setStore(store: Store) {
    SignalR.store = store
  }

  static async start(baseUrl: string) {
    const { dispatch } = SignalR.store
    SignalR.stop()
    SignalR.connection = new signalR.HubConnectionBuilder()
      .configureLogging(process.env.NODE_ENV === 'development' ? LogLevel.Debug : LogLevel.Error)
      .withUrl(`${baseUrl}/api/notification/hubs/events`, {
        accessTokenFactory: () => getToken(SignalR.store.getState()),
        withCredentials: false
      })
      .build()
    SignalR.connection.onclose(async (err: any) => {
      if (err) await startConnection(dispatch, SignalR.connection)
    })

    await startConnection(dispatch, SignalR.connection)
  }

  static addSubscriptionHandler(
    subscriptionString: string,
    createResponseHandler: (dispatch: Dispatch) => (response: any) => void
  ) {
    const { dispatch } = SignalR.store
    SignalR.connection.on(subscriptionString, createResponseHandler(dispatch))
  }

  static stop() {
    if (!SignalR.connection) return
    SignalR.connection.stop()
  }

  static isConnected(): boolean {
    if (!SignalR.connection) return false
    return SignalR.connection.state === signalR.HubConnectionState.Connected
  }
}
