import { FilterScope } from '../filters'
import { TableKey } from '../table'

export enum SelectedDataType {
  Searches = 'Searches',
  CompanyDetailPageViews = 'CompanyDetailPageViews',
  Orders = 'Orders',
  CustomerRequests = 'CustomerRequests'
}

export type Searches = {
  searchViewInternalId: string
  date: string
  salesOrganization: string
  searchQuery: string
  searchCountry: string
  searchFilter: string
  searchResultsCount: number
  loggedUsername: string
  sapSubscriberId: string
  ipAddress: string
  userEmail: string
  subscriberId: string
  firstName: string
  lastName: string
  officeCode: string
  userAgent: string
}

export type CompanyDetailViews = {
  companyViewInternalId: string
  date: string
  salesOrganization: string
  companyName: string
  companyCountry: string
  companyId: string
  loggedUsername: string
  sapSubscriberId: string
  ipAddress: string
  userEmail: string
  subscriberId: string
  firstName: string
  lastName: string
  officeCode: string
  userAgent: string
}

export type Orders = {
  date: string
  salesOrganizationName: string
  orderId: string
  loggedUsername: string
  customerAccounting: string
  userOfficeCode: string
  userCountry: string
  contractType: string
  userType: string
  lastOrderStatus: string
  grossValue: string
  netValue: string
  taxValue: string
  currency: string
  paymentProviderType: string
  paymentId: string
}

export type CustomerRequests = {
  date: string
  salesOrganizationName: string
  orderId: string
  loggedUsername: string
  customerAccounting: string
  userOfficeCode: number
  contractType: string
  userType: string
  companyName: string
  companyIdentifierType: string
  companyIdentifierValue: string
  companyCountry: string
  productCode: string
  deliveryOption: string
  customerRequestStatus: string
  externalSystemOrderNumber: string
  originalGrossPrice: string
  originalNetPrice: string
  currency: string
  lastGrossPrice: string
  lastNetPrice: string
  paymentProviderType: string
  paymentId: string
  customerRequestId: string
}

export type BusinessData = Searches[] | CompanyDetailViews[] | Orders[] | CustomerRequests[]

export type BusinessDataState = {
  product?: string
  selectedDataType?: SelectedDataType
  tableKey?: TableKey
  filterScope?: FilterScope
  data?: BusinessData
}
