export default {
  SelectData: 'Select data',
  briq: 'BRIQ',
  adminx: 'ADMINx',
  margo: 'Margo',
  Searches: 'Searches',
  Orders: 'Orders',
  CustomerRequests: 'Customer Requests',
  OrderId: 'Order ID',
  OrderDate: 'Order Date',
  CompanyDetailPageViews: 'Company detail page views',
  Date: 'Date',
  CreateDate: 'Order creation date',
  PlaceDate: 'Order placement date',
  DateFrom: 'Date (From)',
  DateTo: 'Date (To)',
  CreateDateFrom: 'Order creation - Date (from)',
  CreateDateTo: 'Order creation - Date (to)',
  PlaceDateFrom: 'Order placement - Date (from)',
  PlaceDateTo: 'Order placement - Date (to)',
  SalesOrganization: 'Sales organization',
  SearchQuery: 'Search query',
  SearchCountry: 'Search country',
  SearchFilter: 'Search filter',
  NumberOfSearchResults: 'Number of search results',
  CompanyName: 'Company name',
  CompanyCountry: 'Company country',
  CompanyId: 'Company identifier',
  LoggedUsername: 'Logged username',
  SapSubscriberId: 'SAP subscriber ID',
  IpAddress: 'IP address',
  UserEmail: 'User email',
  InternalSubscriberId: 'Subscriber ID (internal)',
  FirstName: 'First name',
  LastName: 'Last name',
  OfficeCode: 'Office code',
  UserAgent: 'User agent',
  SearchViewInternalId: 'Search view internal Identifier',
  OperatorUserName: 'Operator username',
  CompanyViewInternalId: 'Company view internal Identifier',
  IsOneOf: 'Is one of',
  IsNotOneOf: 'Is not one of',
  All: 'All',
  From: 'From',
  To: 'To',
  Empty: 'Empty',
  CustomerAccounting: 'Customer Accounting Code',
  UserOfficeCode: 'User office Code',
  ContractType: 'Contract Type',
  UserType: 'User Type',
  CompanyIdentifierType: 'Company Identifier Type',
  CompanyIdentifierValue: 'Company Identifier Value',
  ProductCode: 'Product Code',
  DeliveryOption: 'Delivery Option',
  CustomerRequestStatus: 'Customer request status',
  ReportDeliveryDate: 'Report delivery date',
  ExternalSystemOrderNumber: 'External system order number',
  OriginalGrossPrice: 'Original Gross price',
  OriginalNetPrice: 'Original Net price',
  Currency: 'Currency',
  LastGrossPrice: 'Last Gross price',
  LastNetPrice: 'Last Net price',
  PaymentProviderType: 'Payment Provider type',
  PaymentId: 'Payment ID',
  CustomerRequestId: 'Customer Request ID',
  CustomerAccountingCode: 'Customer Accounting Code',
  UserCountry: 'User Country',
  LastOrderStatus: 'Last order status',
  GrossValue: 'Gross Value',
  NetValue: 'Net Value',
  TaxValue: 'Tax Value',
  dialogs: {
    ReportExport: {
      Downloading: 'Preparing your file for download',
      GenerateReport: 'Generating Report',
      DownloadError: 'Error during download',
      DownloadErrorInfo:
        'Something went wrong during report download. Please, try again or contact customer care to report the issue',
      ContinueInBackground: 'You can close this dialog while we continue in the background',
      Close: 'Close',
      OK: 'OK'
    }
  }
}
