export default {
  Home: 'Home',
  Login: 'Login',
  Users: 'Users',
  CreateUser: 'Create user',
  Roles: 'Roles',
  ResetPassword: 'Reset password',
  ImportSubscriber: 'Import subscriber',
  SearchOrganizations: 'Search organizations',
  EditSubscriber: 'Edit subscriber',
  CreateSubscriber: 'Create Subscriber',
  MargoManagement: 'Margò management',
  BusinessData: 'Business Data',
  AccessControl: 'Access Control'
}
