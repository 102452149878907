import { getType, Reducer } from 'typesafe-actions'

import { ConfigActions } from './actions'
import { ConfigState } from './types'
import { SapAsyncActions } from '../sapAsync/actions'
import { withLoadingReducer } from '../loading'

export const initialState: ConfigState = {
  autoLogoutTime: 0,
  timeToCancelAutoLogout: 60,
  isDiscoveryConfigured: undefined,
  domainConfigs: undefined
}

const reducer: Reducer<ConfigState, ConfigActions | SapAsyncActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(ConfigActions.setConfig):
      return {
        ...state,
        ...action.payload
      }
    case getType(ConfigActions.setBriqConfig):
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          briqConfig: action.payload
        }
      }
    case getType(SapAsyncActions.setSapAvailability):
      return {
        ...state,
        isDiscoveryConfigured:
          action.payload.isDiscoveryConfigured === undefined
            ? state.isDiscoveryConfigured
            : action.payload.isDiscoveryConfigured
      }
    default:
      return state
  }
}

export const configReducer = withLoadingReducer(reducer, 'config', initialState)
