import _ from 'lodash'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { withLoadingParametrized } from '../loading'
import { ConfigActions } from './actions'
import { getBriqConfig } from '../config'
import { BriqConfigState } from './types'
import { Dictionary } from '../../utils/types'
import { objectToDictionary } from '../../utils/helpers'
import { getConfig } from '../../utils/configs'

function* loadBriqConfig() {
  const briqConfig: BriqConfigState | undefined = yield select(getBriqConfig)

  if (briqConfig) {
    return
  }

  const config: Dictionary<any> = yield call(getConfig, 'config.briq.json')

  yield put(
    ConfigActions.setBriqConfig({
      defaultReportExpirationDays: objectToDictionary(config.defaultReportExpirationDays ?? {}),
      defaultSearchTimespanInDays: config.defaultSearchTimespanInDays || 14,
      briqUrl: objectToDictionary(config.briqUrl ?? {})
    })
  )
}

export function* configSagas() {
  yield all([
    takeLeading(
      getType(ConfigActions.loadBriqConfig),
      withLoadingParametrized('config', loadBriqConfig, _.constant('briq'))
    )
  ])
}
