import { Suspense, useCallback, lazy } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { DialogType } from '../../redux/dialog/types'
import { getVisibleDialog, getVisibleDialogData } from '../../redux/dialog/selectors'
import { DialogActions } from '../../redux/dialog'
import { RootState } from '../../redux/types'

const DialogRendererComponent = lazy(() => import('./DialogRenderer'))

type Props = StoreProps

const DialogRendererComponentWrapper = ({ dialogType, updateData, setData, dialogData, ...rest }: Props) => {
  const updateDialogData = useCallback(
    (data: any) => {
      if (!dialogType) return
      updateData(data, dialogType)
    },
    [updateData, dialogType]
  )

  const setDialogData = useCallback(
    (data: any) => {
      if (!dialogType) return
      setData(data, dialogType)
    },
    [setData, dialogType]
  )

  return (
    <Suspense fallback={null}>
      {dialogType && (
        <DialogRendererComponent
          updateDialogData={updateDialogData}
          setDialogData={setDialogData}
          dialogType={dialogType}
          data={dialogData}
          {...rest}
        />
      )}
    </Suspense>
  )
}

const mapStateToProps = (state: RootState) => ({
  dialogType: getVisibleDialog(state),
  dialogData: getVisibleDialogData(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: () => dispatch(DialogActions.closeDialog()),
  updateData: (data: any, dialog: DialogType) => dispatch(DialogActions.updateDialogData({ data, dialog })),
  setData: (data: any, dialog: DialogType) => dispatch(DialogActions.updateDialogData({ data, dialog }))
})

type StoreProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const DialogRenderer = connect(mapStateToProps, mapDispatchToProps)(DialogRendererComponentWrapper)
