import { ConnectedRouter } from 'connected-react-router'
import { Switch } from 'react-router'
import { history } from './utils/history'
import { Routes } from './utils/routes'
import { LoadingFallback } from './components/Loading'
import { importLazy } from './utils/import'
import { SiderLayout } from './components/Layout'
import { OnlyUnauthRoute, AuthRoute } from './components/Route'
import { LastLocationProvider } from './components/LastLocation'
import { DialogRenderer } from './containers/DialogRenderer'

const Home = importLazy(() => import('./pages/Home'))
const Login = importLazy(() => import('./pages/Login'))
const OperatorResetPassword = importLazy(() => import('./pages/OperatorResetPassword'))
const OperatorUpdatePassword = importLazy(() => import('./pages/OperatorUpdatePassword'))
const UserList = importLazy(() => import('./pages/UserList'))
const CreateUser = importLazy(() => import('./pages/CreateUser'))
const RoleList = importLazy(() => import('./pages/Roles/RoleList'))
const UserDetail = importLazy(() => import('./pages/UserDetail'))
const EditUser = importLazy(() => import('./pages/EditUser'))
const RoleDetail = importLazy(() => import('./pages/Roles/RoleDetail'))
const RoleEdit = importLazy(() => import('./pages/Roles/RoleEdit'))
const CreateRole = importLazy(() => import('./pages/Roles/CreateRole'))
const ResetPassword = importLazy(() => import('./pages/ResetPassword'))
const ImportSubscriber = importLazy(() => import('./pages/ImportSubscriber'))
const SearchOrganizations = importLazy(() => import('./pages/SearchOrganizations'))
const CreateOffice = importLazy(() => import('./pages/Office/CreateOffice'))
const EditOffice = importLazy(() => import('./pages/Office/EditOffice'))
const EditSubscriber = importLazy(() => import('./pages/Subscriber/EditSubscriber'))
const CreateSubscriber = importLazy(() => import('./pages/Subscriber/CreateSubscriber'))
const MargoSubscription = importLazy(() => import('./pages/Margo/Subscription'))
const MargoTransactionList = importLazy(() => import('./pages/Margo/Transaction'))
const BusinessData = importLazy(() => import('./pages/BusinessData'))
const AccessControl = importLazy(() => import('./pages/AccessControl'))
const LoginAs = importLazy(() => import('./pages/LoginAs'))

export const App = () => {
  return (
    <ConnectedRouter history={history}>
      <DialogRenderer />
      <SiderLayout>
        <LoadingFallback>
          <LastLocationProvider watchOnlyPathname>
            <Switch>
              <AuthRoute path={Routes.Home} exact component={Home} />
              <AuthRoute path={Routes.UserList} exact component={UserList} />
              <AuthRoute path={Routes.CreateUser} exact component={CreateUser} />
              <AuthRoute path={Routes.ResetPassword} component={ResetPassword} />
              <AuthRoute path={Routes.ResetPasswordEmpty} component={ResetPassword} />
              <AuthRoute path={Routes.UserDetail} component={UserDetail} exact />
              <AuthRoute path={Routes.EditUser} component={EditUser} />
              <AuthRoute path={Routes.RoleList} exact component={RoleList} />
              <AuthRoute path={Routes.CreateRole} exact component={CreateRole} />
              <AuthRoute path={Routes.RoleEdit} exact component={RoleEdit} />
              <AuthRoute path={Routes.RoleDetail} component={RoleDetail} />
              <AuthRoute path={Routes.ImportSubscriber} exact component={ImportSubscriber} />
              <AuthRoute path={Routes.SearchOrganizations} exact component={SearchOrganizations} />
              <AuthRoute path={Routes.CreateOffice} component={CreateOffice} />
              <AuthRoute path={Routes.EditOffice} component={EditOffice} />
              <AuthRoute path={Routes.EditSubscriber} component={EditSubscriber} />
              <AuthRoute path={Routes.CreateSubscriber} component={CreateSubscriber} />
              <AuthRoute path={Routes.BusinessData} component={BusinessData} />
              <AuthRoute path={Routes.AccessControl} component={AccessControl} />
              <AuthRoute path={Routes.LoginAs} component={LoginAs} />
              <AuthRoute path={Routes.MargoSubscriptionCreate} exact component={MargoSubscription} />
              <AuthRoute path={Routes.MargoSubscriptionEdit} exact component={MargoSubscription} />
              <AuthRoute path={Routes.MargoTransactionList} component={MargoTransactionList} />
              <OnlyUnauthRoute path={Routes.Login} component={Login} />
              <OnlyUnauthRoute path={Routes.OperatorResetPassword} component={OperatorResetPassword} />
              <OnlyUnauthRoute path={Routes.OperatorUpdatePassword} component={OperatorUpdatePassword} />
            </Switch>
          </LastLocationProvider>
        </LoadingFallback>
      </SiderLayout>
    </ConnectedRouter>
  )
}
