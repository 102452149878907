import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { SapAsyncActions, getIsSapOffline } from '../redux/sapAsync'

export function useSapDiscovery(options?: { skip?: boolean }) {
  const dispatch = useDispatch()
  const skip = options?.skip
  const isSapOffline = useSelector(getIsSapOffline)

  useEffect(() => {
    if (!skip) {
      dispatch(SapAsyncActions.loadSapAvailability())
    }
  }, [skip, dispatch])

  return {
    isSapOffline
  }
}
