export default {
  SelectColumns: 'Select columns',
  Subscriber: 'Subscriber',
  Office: 'Office',
  UserPassword: 'User password',
  SetPassword: 'Set password',
  NewPassword: 'New password',
  NewPasswordPlaceholder: 'Insert new password',
  ConfirmNewPassword: 'Confirm new password',
  ConfirmNewPasswordPlaceholder: 'Confirm new password',
  ConfirmPassword: 'Confirm password',
  ConfirmPasswordPlaceholder: 'Confirm your password',
  GeneratePassword: 'Generate Password',
  SendAutomaticallyEmailWithPassword: 'Send automatically an email to user with new password?',
  ChangePasswordOnFirstLogin: 'Change Password First Login?',
  PasswordNeverExpire: 'Password Never Expire',
  Create: 'Create',
  Retry: 'Retry',
  Save: 'Save',
  ExitWithoutSave: 'Exit without saving',
  CreationDateFrom: 'Creation date (From)',
  CreationDateTo: 'Creation date (To)',
  ProductUserOrAdminxOperator: 'Product User / AdminX Operator',
  ProductUser: 'Product User',
  AdminxOperator: 'Adminx Operator',
  UserOrOperator: 'User / Operator',
  OperatorSuccess: 'Operator was successfully created.',
  OperatorFail: 'We are sorry, but there were error while creating operator.',
  CreationDate: 'Creation date',
  Description: 'Description',
  DomainCode: 'Domain code',
  DeleteUser: 'Delete user',
  UserId: 'User Id',
  UserIdInternal: 'User ID (internal)',
  UserName: 'User name',
  FirstName: 'First name',
  LastName: 'Last name',
  CompanyName: 'Company name',
  CompanySubscriberName: 'Company/Subscriber name',
  EmailAddress: 'Email address',
  SalesOrganization: 'Sales organization',
  SubscriberId: 'Subscriber ID',
  OfficeName: 'Office Name',
  Language: 'Language',
  UserType: 'User type',
  UserStatus: 'User status',
  UserRoles: 'User roles',
  Currency: 'Currency',
  EmailConfirmed: 'Email confirmed',
  ExpirationDate: 'Expiration date',
  ChangePasswordFirstLogin: 'Change password first login',
  PasswordExpired: 'Password expired',
  PasswordExpirationDate: 'Password expiration date',
  PasswordNeverExpired: 'Password never expired',
  LastActivityDate: 'Last activity date',
  LastLockoutDate: 'Last lockout date',
  LastLoginDate: 'Last login date',
  LastPasswordChangedDate: 'Last password changed date',
  IsApproved: 'Is approved',
  IsOnline: 'Is online',
  UserNotes: 'User notes',
  Phone: 'Telephone number',
  Lock: 'Lock',
  Unlock: 'Unlock',
  LockUser: 'Lock user',
  UnlockUser: 'Unlock user',
  ChangeRoles: 'Change roles',
  SubscriberNotFoundTitle: 'Subscriber was not found!',
  SubscriberNotFoundText: 'Please check your filters and try again',
  ServerErrorTitle: 'Server error!',
  ServerErrorText: 'An Error occurred while fetching data from server',
  SapErrorTitle: 'Sap Call - validation error',
  SapSubscriberId: 'SAP subscriber ID',
  SapSubscriberIdPlaceholder: 'Enter SAP Subscriber ID here',
  SapSubscriberCode: 'SAP Subscriber code',
  SapSubscriberCodePlaceholder: 'Enter SAP Subscriber code here',
  SearchSubscriber: 'Search for subscriber',
  ClearFilters: 'Clear filters',
  ClearSearchResults: 'Clear search results',
  SapRefCode: 'SAP Reference code',
  Address: 'Address',
  PublicId: 'Public ID',
  City: 'City',
  Country: 'Country',
  State: 'State',
  ZipCode: 'ZIP Code',
  BillingEmailAddress: 'Billing email address',
  RegistrationType: 'Registration type',
  EntityType: 'Entity type',
  PrivacyFlag: 'Privacy flag',
  PrivacyAcceptanceDate: 'Privacy acceptance date',
  SubscriberStatus: 'Subscriber status',
  MyDocExpirationDays: 'My doc expiration days',
  ExternalSystemCode: 'External system code',
  TermsConditionsFlag: 'I agree to terms and conditions',
  TermsConditionsFlagLabel: 'Terms and conditions flag',
  TermsConditionsAcceptanceDate: 'Terms and conditions acceptance date',
  RegistrationChannel: 'Registration channel',
  PaymentOption: 'Payment option',
  AccountingClientCode: 'Accounting Client Code',
  CustomerDetails: 'Customer details',
  CustomerNotes: 'Customer notes',
  CrifNumber: 'Crif number',
  ExitWithoutSaving: 'Exit without saving',
  RedirectTo: 'You will be redirected to',
  OrganizationsFilters: 'Organizations filters',
  Search: 'Search',
  ResetFilters: 'Reset filters',
  ExternalParentId: 'External parent ID',
  SapAccountingCode: 'Sap accounting code',
  Notes: 'Notes',
  SalesOrganizationId: 'Sales organization ID',
  SalesOrganizationName: 'Sales organization name',
  ProfileCode: 'AccountingProfile',
  OfficeId: 'Office ID',
  Name: 'Name',
  FirstNamePlaceholder: 'Enter first name',
  LastNamePlaceholder: 'Enter last name',
  UserNamePlaceholder: 'Enter user name',
  Email: 'Email',
  EmailPlaceholder: 'Enter email',
  OnlyNumerical: 'can contain only numerical values',
  Roles: 'Roles',
  FormSubmissionError: 'Error while submitting the form!',
  Operation: 'Operation',
  OperationType: 'Operation Type',
  ConsumptionType: 'Consumption Type',
  UsedRings: 'Used Rings',
  UserDistinguishName: 'User Distinguish Name',
  DateTime: 'Date Time',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  UserCreateDialog: {
    SuccessText: 'User has been successfully created.',
    ErrorText: 'There was error while creating user, please try again later.'
  },
  ResetPassword: {
    SuccessText: 'Password has been successfully changed.',
    ErrorText: 'Password cannot be changed, try again later',
    NotFoundErrorText: 'User not found. Please, make sure that all the information is entered correctly.',
    PromptText: 'Do you want to reset password for user {{email}}?',
    NotOldPassword: 'New password cannot be same as 10 last passwords.'
  },
  RecoveryPassword: {
    SuccessText: 'Email with recovery link has been sent.',
    ErrorText: 'Password could not be recovered, please try again later.',
    PromptText: 'Do you want to recover password for user {{email}}?'
  },
  Recover: 'Recover',
  User: 'User',
  SubscriberRoles: 'Subscriber roles',
  UpperRoles: 'Roles at upper levels',
  OfficeRoles: 'Office roles',
  Edit: 'Edit',
  Application: 'Application',
  ApplicationPlaceholder: 'Select application',
  OfficeUpperRoles: 'Office upper roles',
  SubscriberUpperRoles: 'Subscriber upper roles',
  MultipleAddonAdditionForbidden: "It's not possible to add more than one addon at a time. Save the form first.",
  SubscriberType: 'Subscriber Type',
  SendWelcomeEmail: 'Send welcome email to user?',
  Product: 'Product',
  SubscriberCompanyName: 'Company/Subscriber name',
  CreateSubscriber: 'Create subscriber',
  CreateSubscriberAndUser: 'Create subscriber and user',
  UpdateSubscriber: 'Update subscriber',
  BusinessDataType: 'Business data type',
  MargoUserType: 'Margo user type',
  SecurityIndex: 'Security index',
  SelfRegisteredType: 'Self-registered',
  TableNoData:
    'No items were found matching your search request.<br/> -Check the spelling of your keyword<br/> -Try entering fewer filters'
}
