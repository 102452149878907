import { TFunction } from 'i18next'
import _ from 'lodash'
import { LogLevel } from '../redux/logs'
import { ColumnSorting } from '../redux/table'
import { Dictionary, Error, SelectResponse, StatesResponse, SubscriptionLicenseResponse } from './types'
import qs from 'qs'

export function mapActionError(e: Error, level: LogLevel, path?: string) {
  return {
    message: e.message,
    stack: e.stack,
    level,
    path,
    additionalInfo: e.isAxiosError ? JSON.stringify(e) : undefined
  }
}

export function createTFunction(prefix: string, t: TFunction) {
  return (key: string, params?: Dictionary<React.ReactText>) => t(prefix + key, params)
}

export type CTFunction = ReturnType<typeof createTFunction>

/* istanbul ignore next */
export function wait(delay: number) {
  return new Promise(resolve => setTimeout(() => resolve(undefined), delay))
}

export const mapSelectResponseToOptions = (res?: SelectResponse) =>
  _.map(res?.values, x => ({ value: x.value, label: x.key }))

export const mapStatesResponseToOptions = (res?: StatesResponse) =>
  _.map(res?.states, x => ({ value: x.stateCode, label: x.description }))

export const mapSubscriptionLicensesResponseToOptions = (res?: SubscriptionLicenseResponse) =>
  _.map(res?.subscriptionLicenses, x => ({ value: x.license, label: x.licenseCode }))

export const mapSortingToApi = (sorting: Dictionary<ColumnSorting>) => {
  const property = _.head(_.keys(sorting))
  if (property) {
    return {
      property,
      ascending: sorting[property].order === 'ascend'
    }
  }
  return undefined
}

export const mapSortingToMargoApi = (sorting: Dictionary<ColumnSorting>) => {
  const s = mapSortingToApi(sorting)

  if (!s) return undefined

  return {
    property: s.property,
    order: s.ascending ? 'Asc' : 'Desc'
  }
}

export function parseGetParams(query: string) {
  const parsedQuery = _.startsWith(query, '?') ? query.substring(1) : query
  return qs.parse(parsedQuery, { allowDots: true })
}

export function stringifyGetParams(query: object) {
  return qs.stringify(query, { allowDots: true })
}

export const objectToDictionary = (object: any) => {
  const dictionary: { [id: string]: string | undefined } = {}
  for (const [key, value] of Object.entries(object)) {
    dictionary[key] = value ? _.toString(value) : undefined
  }
  return dictionary
}
