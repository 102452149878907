import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { Color } from '../../theme'
import { textMixin, TextMixinProps, Offset, TitleSize, titleLevelProps } from './common'

const Style = styled.div<TextMixinProps>`
  ${textMixin}
  color: ${({ theme, color }) => (color && theme.colors[color]) || theme.colors.primary};

  span {
    ${textMixin}
  }

  &.with-image {
    position: relative;
    .img-label {
      position: absolute;
      top: 4px;
      left: -8px;
      width: 22px;
      height: 22px;
      transform: rotate(-45deg);
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.secondary};
      z-index: 0;
    }
    span {
      z-index: 1;
      position: relative;
    }
  }
`

export type TitleProps = {
  className?: string
  style?: React.CSSProperties
  level?: TitleSize
  center?: boolean
  ellipsis?: boolean
  margin?: Offset
  padding?: Offset
  text?: string
  color?: Color
  children?: React.ReactNode
  withImage?: boolean
  bold?: boolean
}

const defaultProps = {
  center: false,
  ellipsis: false,
  lineHeight: 1.5,
  margin: 0
}

export const Title = ({ level, text, children, withImage, className, ...props }: TitleProps) => {
  const themeContext = useContext(ThemeContext)
  if (withImage) {
    return (
      <Style
        {...defaultProps}
        {...titleLevelProps(themeContext, level)}
        {...props}
        className={`${className || ''} with-image`}
      >
        <div className="img-label" />
        <span>{children || text}</span>
      </Style>
    )
  }

  return (
    <Style
      {...defaultProps}
      {...titleLevelProps(themeContext, level)}
      {...props}
      className={`${className || ''} ${level}`}
    >
      {children || text}
    </Style>
  )
}

Title.Style = Style
