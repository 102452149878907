import { getType, Reducer } from 'typesafe-actions'

import { RoleListActions } from './actions'
import { RoleListState } from './types'
import { withLoadingReducer } from '../loading'

export const initialState: RoleListState = {
  data: []
}

export const reducer: Reducer<RoleListState, RoleListActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(RoleListActions.setRoles):
      return {
        ...state,
        data: action.payload.roles
      }
    default:
      return state
  }
}

export const roleListReducer = withLoadingReducer(reducer, 'roleList', initialState)
