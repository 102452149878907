import { HubEventBase } from 'react-eventr'
import { Dictionary } from '../../utils/types'

export enum HubEventType {
  MargoCustomIndexesChanged = 'MargoCustomIndexesChanged',
  ExportCompleted = 'ExportCompleted',
  ExportFailed = 'ExportFailed'
}

type WithExportId = { exportId: string }

export type HubEvent =
  | HubEventBase<HubEventType.MargoCustomIndexesChanged, Dictionary<boolean>>
  | HubEventBase<HubEventType.ExportCompleted, WithExportId>
  | HubEventBase<HubEventType.ExportFailed>
