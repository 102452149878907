import { getType, Reducer } from 'typesafe-actions'
import { FilterScope } from '../filters'
import { withLoadingReducer } from '../loading'
import { TableKey } from '../table'

import { BusinessDataActions } from './actions'
import { BusinessDataState, SelectedDataType } from './types'

export const initialState: BusinessDataState = {}

export const reducer: Reducer<BusinessDataState, BusinessDataActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(BusinessDataActions.selectData):
      return {
        ...state,
        product: action.payload.product,
        selectedDataType: action.payload.selectedDataType,
        tableKey:
          action.payload.selectedDataType === SelectedDataType.Searches
            ? TableKey.BusinessSearchesDataList
            : action.payload.selectedDataType === SelectedDataType.CompanyDetailPageViews
            ? TableKey.BusinessCompanyDetailDataList
            : action.payload.selectedDataType === SelectedDataType.Orders
            ? TableKey.BusinessOrdersDataList
            : action.payload.selectedDataType === SelectedDataType.CustomerRequests
            ? TableKey.BusinessCustomerRequestsDataList
            : undefined,
        filterScope:
          action.payload.selectedDataType === SelectedDataType.Searches
            ? FilterScope.BusinessSearchesDataList
            : action.payload.selectedDataType === SelectedDataType.CompanyDetailPageViews
            ? FilterScope.BusinessCompanyDetailDataList
            : action.payload.selectedDataType === SelectedDataType.Orders
            ? FilterScope.BusinessOrdersDataList
            : action.payload.selectedDataType === SelectedDataType.CustomerRequests
            ? FilterScope.BusinessCustomerRequestsDataList
            : undefined,
        data: initialState.data
      }
    case getType(BusinessDataActions.setData):
      return {
        ...state,
        data: action.payload.data
      }
    case getType(BusinessDataActions.resetData):
      return initialState
    default:
      return state
  }
}

export const businessDataReducer = withLoadingReducer(reducer, 'businessData', initialState)
