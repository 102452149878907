import { getType } from 'typesafe-actions'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { AuthActions } from './actions'
import { Routes } from '../../utils/routes'
import { push } from 'connected-react-router'
import { getUsername } from './selectors'
import { apiCall } from '../../utils/api'
import { AxiosResponse } from 'axios'
import { ClaimsResponse } from '.'

export function* handleLogout() {
  yield put(
    AuthActions.setToken({
      token: ''
    })
  )

  yield put(
    AuthActions.setClaims({
      claims: []
    })
  )

  yield put(push(Routes.Login))
}

type TokenResponse = {
  token: string
}

export function* handleRefreshToken() {
  const username: string = yield select(getUsername)

  if (!username) return

  const { data }: AxiosResponse<TokenResponse> = yield call(apiCall, { url: `/api/token/${username}`, method: 'get' })

  yield put(AuthActions.setToken({ token: data.token }))
}

export function* handleFetchClaims() {
  const username: string = yield select(getUsername)

  if (!username) return

  const { data }: AxiosResponse<ClaimsResponse> = yield call(apiCall, { url: `/api/Roles/userClaims`, method: 'get' })

  yield put(AuthActions.setClaims({ claims: data.claims }))
}

export function* authSagas() {
  yield all([
    takeLatest(getType(AuthActions.logout), handleLogout),
    takeLatest(getType(AuthActions.refreshToken), handleRefreshToken),
    takeLatest(getType(AuthActions.fetchClaims), handleFetchClaims)
  ])
}
