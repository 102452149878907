import _ from 'lodash'
import { createSelector } from 'reselect'
import { paramSelector } from '../../utils/selectors'
import { RootState } from '../types'

const selectState = (state: RootState) => state.config

export const getConfig = createSelector(selectState, state => state)

export const getAutoLogoutTime = createSelector(selectState, state => state.autoLogoutTime)

export const getTimeToCancelAutoLogout = createSelector(selectState, state => state.timeToCancelAutoLogout)

export const getDefaultReportExpirationDays = createSelector(
  selectState,
  state => state.domainConfigs?.briqConfig?.defaultReportExpirationDays ?? undefined
)

export const getDefaultSearchTimespanInDays = createSelector(
  selectState,
  state => state.domainConfigs?.briqConfig?.defaultSearchTimespanInDays ?? undefined
)

export const getBriqConfig = createSelector(selectState, state => state.domainConfigs?.briqConfig)

export const getBriqUrl = createSelector(
  selectState,
  paramSelector<{ domain: string }>(),
  (state, { domain }) => state.domainConfigs?.briqConfig?.briqUrl[_.toLower(domain)] ?? undefined
)

export const getIsDiscoveryConfigured = createSelector(selectState, state => state.isDiscoveryConfigured)

export const getBriqConfigLoading = createSelector(selectState, state => state.loadingEntities['briq'] ?? false)
