import { getType, Reducer } from 'typesafe-actions'

import { TransactionListActions } from './actions'
import { TransactionListState } from './types'
import { withLoadingReducer } from '../loading'

export const initialState: TransactionListState = {
  data: [],
  subscriptionId: ''
}

export const reducer: Reducer<TransactionListState, TransactionListActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(TransactionListActions.setTransactions):
      return {
        ...state,
        data: action.payload.transactions
      }
    case getType(TransactionListActions.setSubscriptionId):
      return {
        ...state,
        subscriptionId: action.payload.subscriptionId
      }
    default:
      return state
  }
}

export const transactionListReducer = withLoadingReducer(reducer, 'transactionList', initialState)
