import { ActionType, createAction } from 'typesafe-actions'
import { ColumnType } from '../../components/Table'
import { SelectedDataType, ManageAccessControlData, UnblockBlockedSiteData } from './types'

export const AccessControlActions = {
  selectData: createAction('ACCESS-CONTROL/select-data')<{ product: string; selectedDataType: SelectedDataType }>(),
  loadData: createAction('ACCESS-CONTROL/load-data')(),
  // TODO: fix type
  setData: createAction('ACCESS-CONTROL/set-data')<{ data: any }>(),
  resetData: createAction('ACCESS-CONTROL/reset-data')(),
  addData: createAction('ACCESS-CONTROL/add-data')<{ data: ManageAccessControlData }>(),
  unblockSite: createAction('ACCESS-CONTROL/unblock-site')<{ data: UnblockBlockedSiteData }>(),
  makeReport: createAction('ACCESS-CONTROL/make-report')<{ columns: ColumnType[] }>()
}

export type AccessControlActions = ActionType<typeof AccessControlActions>
