import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode'
import { RootState } from '../types'
import { TokenPayload } from './types'
import _ from 'lodash'

const selectState = (state: RootState) => state.auth

export const getToken = createSelector(selectState, s => s.token)

export const getDecodedToken = createSelector(getToken, t => (t ? (jwtDecode(t) as TokenPayload) : null))

export const isUserLoggedIn = createSelector(selectState, s => !!s.token)

export const getFullName = createSelector(getDecodedToken, t =>
  t ? `${t['admx.firstname']} ${t['admx.lastname']}` : ''
)

export const getUserEmail = createSelector(getDecodedToken, t => (t ? t['admx.email'] : ''))

export const getName = createSelector(getDecodedToken, t => (t ? t['admx.firstname'] : ''))

export const getSurname = createSelector(getDecodedToken, t => (t ? t['admx.lastname'] : ''))

export const getUsername = createSelector(getDecodedToken, t => (t ? t['admx.username'] : ''))

export const getUserRoles = createSelector(getDecodedToken, t =>
  t ? t['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : []
)

export const getUserClaims = createSelector(selectState, s => s.claims)

export const getUserClaimCodes = createSelector(getUserClaims, s => _.map(s, s => s.code))
