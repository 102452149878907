import { getType, Reducer } from 'typesafe-actions'
import { withLoadingReducer } from '../loading'

import { SapAsyncActions } from './actions'
import { SapAsyncState } from './types'

export const initialState: SapAsyncState = { isSapOffline: false }

export const reducer: Reducer<SapAsyncState, SapAsyncActions> = (state = initialState, action) => {
  if (action.type === getType(SapAsyncActions.setSapAvailability)) {
    return {
      isSapOffline: action.payload.isOnline === false
    }
  }
  return state
}

export const sapAsyncReducer = withLoadingReducer(reducer, 'sapAsync', initialState)
