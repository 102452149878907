import { getType, Reducer } from 'typesafe-actions'
import { LogActions } from './actions'
import { LogItem } from './types'

export type LogState = {
  logItems: LogItem[]
}

export const initState: LogState = {
  logItems: []
}

export const logReducer: Reducer<LogState, LogActions> = (state = initState, action) => {
  switch (action.type) {
    case getType(LogActions.addLogItem):
      // eslint-disable-next-line no-case-declarations
      const { message, stack, level, path } = action.payload
      // eslint-disable-next-line no-case-declarations
      const logItem = {
        level,
        message,
        stack,
        path
      }
      return {
        ...state,
        logItems: [...state.logItems, logItem]
      }
    default:
      return state
  }
}
