import { FilterScope } from '../filters'
import { TableKey } from '../table'

export enum SelectedDataType {
  SecurityBlockedUsers = 'SecurityBlockedUsers',
  SecurityBlockedSites = 'SecurityBlockedSites',
  SecurityWhitelist = 'SecurityWhiteList'
}

export type BlockedSitesData = {
  id: string
  date: string
  countryCode: string
}

export type BlockedUsersData = {
  id: string
  date: string
  countryCode: string
  email: string
  ipAddress: string
}

export type WhitelistData = {
  id: string
  date: string
  countryCode: string
  email: string
  ipAddress: string
  userAgentTag: string
}

export type CreateBlockedUsersData = {
  email?: string
  ipAddress?: string
  countryCode: string
}

export type CreateWhitelistData = {
  email?: string
  ipAddress?: string
  countryCode: string
  userAgentTag: string
}

export type UnblockBlockedSiteData = {
  salesOrganizationId: string
}

export type ManageAccessControlData = CreateWhitelistData | UnblockBlockedSiteData

export type AccessControlData = BlockedSitesData | BlockedUsersData | WhitelistData

export type AccessControlState = {
  product?: string
  tableKey?: TableKey
  selectedDataType?: SelectedDataType
  data?: AccessControlData[]
  filterScope?: FilterScope
}
