import { Dictionary } from '../../utils/types'

export enum TableKey {
  UserList = 'UserList',
  RoleList = 'RoleList',
  TransactionList = 'TransactionList',
  BusinessSearchesDataList = 'BusinessSearchesDataList',
  BusinessCompanyDetailDataList = 'BusinessCompanyDetailDataList',
  BusinessOrdersDataList = 'BusinessOrdersDataList',
  BusinessCustomerRequestsDataList = 'BusinessCustomerRequestsDataList',
  AccessControlBlockedSitesDataList = 'AccessControlBlockedSitesDataList',
  AccessControlBlockedUsersDataList = 'AccessControlBlockedUsersDataList',
  AccessControlWhitelistDataList = 'AccessControlWhitelistDataList'
}

export type Identifier = string

export type SortOrder = 'ascend' | 'descend'

export interface ColumnSorting {
  order: SortOrder
}

export interface PaginationConfig {
  current: number
  total: number
  pageSize: number
}

export interface TableConfig {
  sorting: Dictionary<ColumnSorting>
  visibleColumns?: Identifier[]
  rowSelection: Identifier[]
  pagination: PaginationConfig
}

export type TableState = Record<TableKey, TableConfig>
