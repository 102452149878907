import { put, call } from 'redux-saga/effects'
import { LoadingActions } from './actions'
import { Error } from '../../utils/types'
import { LoadingScope } from './types'
import _ from 'lodash'

export function withLoadingParametrized<T = any, U = string>(
  scope: LoadingScope,
  handler: (action: T) => unknown,
  createParams: (action: T) => U,
  // eslint-disable-next-line
  errorHandler?: (e: Error) => void
) {
  return function* loader(action: T) {
    const params: string = yield call(createParams, action)
    try {
      if (_.isArray(params)) {
        for (let i = 0; i < params.length; i++) {
          yield put(LoadingActions.setLoading({ scope, loading: true, entityId: params[i] }))
        }
      } else {
        yield put(LoadingActions.setLoading({ scope, loading: true, entityId: params }))
      }

      yield call(handler, action)
    } catch (e) {
      if (errorHandler) {
        yield call(errorHandler, e)
      } else throw e
    } finally {
      if (_.isArray(params)) {
        for (let i = 0; i < params.length; i++) {
          yield put(LoadingActions.setLoading({ scope, loading: false, entityId: params[i] }))
        }
      } else {
        yield put(
          LoadingActions.setLoading({
            loading: false,
            scope,
            entityId: params
          })
        )
      }
    }
  }
}

export function withLoading<T = any>(
  scope: LoadingScope,
  handler: (action: T) => unknown,
  // eslint-disable-next-line
  errorHandler?: (e: Error) => void
) {
  return function* loader(action: T) {
    try {
      yield put(LoadingActions.setLoading({ scope, loading: true }))
      yield call(handler, action)
    } catch (e) {
      if (errorHandler) {
        yield call(errorHandler, e)
      } else throw e
    } finally {
      yield put(
        LoadingActions.setLoading({
          loading: false,
          scope
        })
      )
    }
  }
}
