export enum Routes {
  Home = '/',
  Login = '/sign-in',
  OperatorResetPassword = '/reset-password',
  OperatorUpdatePassword = '/update-password',
  UserDetail = '/users/:userId',
  UserList = '/users',
  CreateUser = '/users/create',
  EditUser = '/users/:userId/edit',
  RoleList = '/roles',
  RoleEdit = '/roles/:roleId/edit',
  RoleDetail = '/roles/:roleId',
  CreateRole = '/roles/create',
  ResetPassword = '/users/resetPassword/:domainCode?/:email?',
  ResetPasswordEmpty = '/users/resetPassword/',
  ImportSubscriber = '/subscribers/import',
  EditSubscriber = '/subscribers/:subscriberId/edit',
  CreateSubscriber = '/subscribers/create',
  MargoSubscriptionEdit = '/subscription/margo/:subscriberId/edit/:organizationId/:subscriptionId',
  MargoTransactionList = '/subscription/margo/:subscriberId/edit/:organizationId/:subscriptionId/transactions',
  MargoSubscriptionCreate = '/subscription/margo/:subscriberId/create',
  SearchOrganizations = '/organizations/search',
  EditSalesOrganization = '/salesOrganizations/:salesOrganizationId/edit',
  CreateOffice = '/office/create',
  EditOffice = '/office/edit/:officeId',
  BusinessData = '/business-data',
  AccessControl = '/access-control',
  LoginAs = '/login-as'
}
