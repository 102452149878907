import _ from 'lodash'
import moment, { Moment } from 'moment'
import { all, put, takeLatest, select, call, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { AxiosResponse } from 'axios'
import { apiCall } from '../../utils/api'
import { AccessControlActions } from './actions'
import { PaginationConfig, TableActions, TableKey, ColumnSorting } from '../table'
import { FiltersActions, FilterScope, getFilterValues } from '../filters'
import { SelectedDataType, AccessControlData } from './types'
import { getSelectedDataType } from './selectors'
import { withLoading } from '../loading'
import { selectPagination, selectSorting, selectVisibleColumns } from '../table/selectors'
import { Dictionary } from '../../utils/types'
import { getTableKey, getFilterScope } from '.'
import { mapSortingToApi } from '../../utils/helpers'
import { exportData, mapExportHeaders } from '../../utils/export'

type AccessControlDataResponse = {
  currentPage: 1
  pageSize: 25
  pagesCount: 2
  recordsCount: number
  records: AccessControlData[]
}

const tableKeys = [
  TableKey.AccessControlBlockedSitesDataList,
  TableKey.AccessControlBlockedUsersDataList,
  TableKey.AccessControlWhitelistDataList
]

const filterScopes = [
  FilterScope.AccessControlBlockedSitesDataList,
  FilterScope.AccessControlBlockedUsersDataList,
  FilterScope.AccessControlWhitelistDataList
]

const selectedDataTypeToUrlMap = {
  [SelectedDataType.SecurityBlockedSites]: 'blockedSite',
  [SelectedDataType.SecurityBlockedUsers]: 'blockedUser',
  [SelectedDataType.SecurityWhitelist]: 'whitelist'
}

export function* loadAccessControlData() {
  const selectedData: SelectedDataType = yield select(getSelectedDataType)
  const tableKey: TableKey = yield select(getTableKey)
  const filtersScope: FilterScope = yield select(getFilterScope)

  if (!selectedData) return

  const pagination: PaginationConfig = yield select(selectPagination, { tableKey })
  const sorting: Dictionary<ColumnSorting> = yield select(selectSorting, { tableKey })

  const urlSpecificPath = selectedDataTypeToUrlMap[selectedData]

  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: filtersScope })

  const columnFilterKeys = _.keys(filters)
  const columnFilterKeysWithoutDate = _.filter(columnFilterKeys, f => f !== 'dateFrom' && f !== 'dateTo')
  const columnFilter = _.map(columnFilterKeysWithoutDate, f => filters[f])

  const { data }: AxiosResponse<AccessControlDataResponse> = yield call(apiCall, {
    url: `/api/${urlSpecificPath}/search`,
    method: 'post',
    data: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters: {
        dateFrom: filters.dateFrom ? moment(filters.dateFrom as Moment).format('YYYY-MM-DD') : undefined,
        dateTo: filters.dateTo
          ? moment(filters.dateTo as Moment)
              .add(1, 'days')
              .format('YYYY-MM-DD')
          : undefined,
        filters: columnFilter
      },
      sort: mapSortingToApi(sorting)
    }
  })

  yield put(AccessControlActions.setData({ data: data.records }))

  yield put(
    TableActions.setPagination({
      tableKey: tableKey,
      pagination: {
        current: pagination.current,
        pageSize: data.pageSize,
        total: data.recordsCount
      }
    })
  )
}

export function* generateFilters(filtersScope: FilterScope) {
  const filters: Dictionary<unknown> = yield select(getFilterValues, { scope: filtersScope })
  const columnFilterkeys = _.keys(filters)
  const columnFilterKeysWithoutDate = _.filter(
    columnFilterkeys,
    f => f !== 'dateFrom' && f !== 'dateTo' && f !== 'placeDateFrom' && f !== 'placeDateTo'
  )
  const columnFilters = _.map(columnFilterKeysWithoutDate, f => filters[f])

  const dateFrom = filters.dateFrom ? moment(filters.dateFrom as Moment).format('YYYY-MM-DD') : undefined
  const dateTo = filters.dateTo
    ? moment(filters.dateTo as Moment)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    : undefined

  const placeDateFrom = filters.placeDateFrom ? moment(filters.placeDateFrom as Moment).format('YYYY-MM-DD') : undefined
  const placeDateTo = filters.placeDateTo
    ? moment(filters.placeDateTo as Moment)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    : undefined

  return {
    columnFilters,
    filters,
    dateFrom,
    dateTo,
    placeDateFrom,
    placeDateTo
  }
}

export function* exportAccessControl({ payload }: ReturnType<typeof AccessControlActions.makeReport>) {
  const { columns } = payload
  const selectedData: SelectedDataType = yield select(getSelectedDataType)
  const tableKey: TableKey = yield select(getTableKey)
  const filtersScope: FilterScope = yield select(getFilterScope)
  const visibleColumns: string[] = yield select(selectVisibleColumns, { tableKey })

  const urlSpecificPath = selectedDataTypeToUrlMap[selectedData]
  const exportHeaders = mapExportHeaders(columns, visibleColumns)

  if (!selectedData || !tableKey || !filtersScope || !urlSpecificPath) return

  const generatedFilters: {
    columnFilters: unknown[]
    dateFrom: string
    dateTo: string
    placeDateFrom: string
    placeDateTo: string
  } = yield call(generateFilters, filtersScope)

  yield exportData(urlSpecificPath, {
    dateFrom: generatedFilters.dateFrom,
    dateTo: generatedFilters.dateTo,
    placeDateFrom: generatedFilters.placeDateFrom,
    palceDateTo: generatedFilters.placeDateTo,
    filters: generatedFilters.columnFilters,
    columns: exportHeaders
  })
}

export function* handleChangePagination(action: ReturnType<typeof TableActions.changePagination>) {
  if (!_.includes(tableKeys, action.payload.tableKey)) return

  yield put(AccessControlActions.loadData())
}

export function* handleChangeSorting(action: ReturnType<typeof TableActions.setSorting>) {
  if (!_.includes(tableKeys, action.payload.tableKey)) return

  yield put(TableActions.setPage({ tableKey: action.payload.tableKey, page: 1 }))
  yield put(AccessControlActions.loadData())
}

export function* handleChangeFilters(action: ReturnType<typeof FiltersActions.setFilters>) {
  if (!_.includes(filterScopes, action.payload.scope)) return

  const tableKey: TableKey = yield select(getTableKey)
  if (!tableKey) return

  yield put(TableActions.setPage({ tableKey, page: 1 }))
  yield put(AccessControlActions.loadData())
}

export function* handleAddToIndex(action: ReturnType<typeof AccessControlActions.addData>) {
  const selectedData: SelectedDataType = yield select(getSelectedDataType)

  const urlSpecificPath = selectedDataTypeToUrlMap[selectedData]

  yield call(apiCall, {
    url: `/api/${urlSpecificPath}`,
    method: 'post',
    data: action.payload.data
  })

  yield put(AccessControlActions.loadData())
}

export function* handleUnblockSite(action: ReturnType<typeof AccessControlActions.unblockSite>) {
  yield call(apiCall, {
    url: `/api/blockedSite/unblock`,
    method: 'post',
    data: action.payload.data
  })

  yield put(AccessControlActions.loadData())
}

export function* accessControlSagas() {
  yield all([
    takeLatest(getType(AccessControlActions.loadData), withLoading('accessControl', loadAccessControlData)),
    takeLatest(getType(TableActions.changePagination), handleChangePagination),
    takeLatest(getType(FiltersActions.setFilters), handleChangeFilters),
    takeLatest(getType(TableActions.setSorting), handleChangeSorting),
    takeEvery(getType(AccessControlActions.addData), handleAddToIndex),
    takeEvery(getType(AccessControlActions.unblockSite), handleUnblockSite),
    takeLatest(getType(AccessControlActions.makeReport), exportAccessControl)
  ])
}
