import { Suspense } from 'react'
import { CenterChildren } from '../Center'
import { Spin, SpinSize } from '../Spin'

export const LoadingFallback = ({ children }: { children: JSX.Element }) => (
  <Suspense
    fallback={
      <CenterChildren>
        <Spin size={SpinSize.ExtraLarge} />
      </CenterChildren>
    }
  >
    {children}
  </Suspense>
)
