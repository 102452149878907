import _ from 'lodash'
import { createGlobalStyle, css, DefaultTheme, FlattenInterpolation, ThemedStyledProps } from 'styled-components/macro'

export const theme = {
  colors: {
    primary: '#003c7d',
    secondary: '#fec74a',
    info: '#17a2b8',
    success: '#28a745',
    warning: '#ff7400',
    danger: '#dc3545',

    primaryLightest: '#CED9E5',
    primaryLighter: '#7E9CBD',
    primaryLight: '#336297',
    primaryDark: '#1F3361',
    primaryDarker: '#192C55',
    primaryDarkest: '#122549',

    secondaryLightest: '#FEF3DA',
    secondaryLighter: '#FEE2A3',
    secondaryLight: '#FED881',
    secondaryDark: '#CB9F3B',
    secondaryDarker: '#98772C',
    secondaryDarkest: '#654F1D',

    orange: '#ff7400',
    pumpkinOrange: '#ff7500',
    lightestGray: '#f4f4f4',
    lightGray: '#f9f9f9',
    gray: '#dfdfdf',
    darkGray: '#505050',
    white: '#FFFFFF',

    bgPrimary: '#f9f9f9',
    bgSecondary: '#E0E0E0',
    borderPrimary: '#CACACA',

    buttonTextPrimary: '#ffffff',
    buttonTextSecondary: '#ffffff',

    textPrimary: 'black',
    textSecondary: '#222',
    textMuted: '#a2a2a2',

    transparent: 'transparent',
    textPrimaryInverse: 'white'
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '16px',
    large: '24px',
    extraLarge: '32px'
  },
  titleFontSizes: {
    extraSmall: '14px',
    small: '16px',
    medium: '24px',
    large: '32px',
    extraLarge: '40px'
  },
  breakpoints: ['1024px', '1024px', '1920px'],
  space: ['0px', '8px', '16px', '24px', '32px', '40px']
}

export type Color = keyof DefaultTheme['colors']
export type FontSize = keyof DefaultTheme['fontSizes']
export type TitleFontSize = keyof DefaultTheme['fontSizes']
export type Spaces = number
export type Breakpoints = keyof DefaultTheme['breakpoints']

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    font-family: 'Montserrat', sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: normal;
    width: 100%;
    height: 100%;
    margin: 0;
    color: ${({ theme }) => theme.colors.primary};
  }

  .ant-modal-mask {
    background-color: rgba(0, 60, 125, 0.6);
  }

  .briq-dialog {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal {
      position: static;
      margin: 0;
      padding: 0;
      max-height: 100vh;
    }

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: large;
      svg {
        fill: white;
      }
    }


    .ant-modal-content {
      border-radius: 4px;
      position: relative;
      overflow: hidden;
    }

    .ant-modal-header {
      padding: 24px 10%;
      box-sizing: border-box;
      border-bottom: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      padding: 20px 10% 20px 10%;
      border-top: none;
    }

    ${brkmax(
      1,
      css`
        & {
          right: auto;
          bottom: auto;
          width: 100vw;
          height: 100vh;
        }

        .ant-modal {
          position: fixed !important;
          top: 0;
          padding: 0;
          margin: 0;
          width: unset !important;
          left: 0;
          overflow: auto;
          min-width: 100%;
          height: 100%;
        }

        .ant-modal-content {
          border-radius: 0;
          overflow-y: auto;
          max-height: 100vh;
        }

        .ant-modal-header {
          padding: 12px 5%;
        }

        .dialog-body-wrapper {
          margin: 0;
          padding: 5%;
        }

        .ant-modal-close {
          right: 17px;
          .ant-modal-close-x {
            width: 60px;
            height: 60px;

            svg {
              width: 1.4em !important;
              height: 1.4em !important;
              fill: ${theme.colors.primary};
            }
          }
        }
      `
    )}
  }

  .adminx-table-transfer {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${themeColor('primary')};
        border-color: ${themeColor('primary')};
      }
    }

    .ant-transfer-operation {
      .ant-btn-primary {
        background: ${themeColor('primary')};
        border-color: ${themeColor('primary')};
        color: ${themeColor('white')};
      }
    }

    .ant-pagination-item-active {
      border-color: ${themeColor('primary')};
      a {
        color: ${themeColor('primary')};
      }
    }
  }
`

export function themeColor(color: Color): (p: ThemedStyledProps<unknown, DefaultTheme>) => string {
  return ({ theme }) => theme.colors[color]
}

export function themeSpace(space: Spaces): (p: ThemedStyledProps<unknown, DefaultTheme>) => string {
  return ({ theme }) => theme.space[space]
}

export function themeFontSize(size: FontSize): (p: ThemedStyledProps<unknown, DefaultTheme>) => string {
  return ({ theme }) => theme.fontSizes[size]
}

export function themeTitleFontSize(size: TitleFontSize): (p: ThemedStyledProps<unknown, DefaultTheme>) => string {
  return ({ theme }) => theme.titleFontSizes[size]
}

export function brkmax(
  max: Breakpoints | string,
  styles: FlattenInterpolation<ThemedStyledProps<unknown, DefaultTheme>>
) {
  if (_.isString(max)) {
    return css`
      @media (max-width: ${max}) {
        ${styles}
      }
    `
  }

  return css`
    @media (max-width: ${theme.breakpoints[max as any]}) {
      ${styles}
    }
  `
}
