export default {
  PasswordResetTitle: 'Password reset',
  PasswordResetText: 'Please enter your email. You will receive link to reset your password.',
  PasswordUpdateTitle: 'Password update',
  PasswordUpdateText:
    'Please enter new password. The password must be between 8 and 25 characters (letters or numbers). The password must contain at least one capital letter and one number. At most two consecutive identical characters',
  PasswordUpdateFailed: 'Password update failed because of internal error.',
  PasswordUpdateSuccess: 'The password was successfully changed.',
  ExpiredToken: 'Your Password reset link has expired. Please, request a new link.',
  Send: 'Send',
  SuccessfullySent: 'If a matching account was found an email was sent to {{email}}',
  BackToLogin: 'Back to login'
}
